// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { analyzeText, setArticleText, setArticleTextFromWeb } from "../redux/textsSlice";
// import { Form, Button, Spinner } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css'; // Импортируем стили Quill

// const AddTextPage = () => {
//   const [text, setText] = useState("");
//   const [title, setTitle] = useState("");
//   const [theme, setTheme] = useState("");
//   const [articleLanguage, setArticleLanguage] = useState("de-DE");
//   const [translationLanguage, setTranslationLanguage] = useState("ru-RU");
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState("");

//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { themes, articleText } = useSelector((state) => state.texts);
//   const user = useSelector((state) => state.texts.user);

//   useEffect(() => {
//     if (articleText) {
//       setText(articleText);
//     }
//   }, [articleText]);

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (!text || !title || !theme || !articleLanguage || !translationLanguage) {
//       setError("Please fill in all fields.");
//       return;
//     }
//     if (!user || user.role !== 'admin' && user.role !== 'editor') {
//       alert('You do not have permission to analyze text.');
//       return;
//     }
//     setLoading(true);
//     dispatch(analyzeText({ text, title, theme_id: theme, language: articleLanguage, translation_language: translationLanguage }))
//       .then(() => {
//         navigate(-1); // Вернуться на предыдущую страницу
//         dispatch(setArticleText(text));
//       })
//       .catch((error) => {
//         console.error("Error analyzing text:", error);
//       })
//       .finally(() => {
//         setLoading(false);
//         setError("");
//       });
//   };

//   return (
//     <div className="container mt-1">
//       <h5 className="mt-2 mb-3">Add Text</h5>
//       <Form onSubmit={handleSubmit}>
//         <Form.Group controlId="formTitle">
//           <Form.Label>Title</Form.Label>
//           <Form.Control
//             type="text"
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             placeholder="Enter title here..."
//           />
//         </Form.Group>
//         <Form.Group controlId="formTheme">
//           <Form.Label>Theme</Form.Label>
//           <Form.Control
//             as="select"
//             value={theme}
//             onChange={(e) => setTheme(e.target.value)}
//           >
//             <option value="">Select theme</option>
//             {themes.map((theme) => (
//               <option key={theme.id} value={theme.id}>
//                 {theme.name}
//               </option>
//             ))}
//           </Form.Control>
//         </Form.Group>
//         <Form.Group controlId="formLanguage">
//           <Form.Label>Language</Form.Label>
//           <Form.Control
//             as="select"
//             value={articleLanguage}
//             onChange={(e) => setArticleLanguage(e.target.value)}
//           >
//             <option value="de-DE">Deutsch (Deutschland)</option>
//             <option value="ru-RU">Русский (Россия)</option>
//             <option value="en-US">English (US)</option>
//             <option value="es-ES">Spanish (Spain)</option>
//             <option value="fr-FR">French (France)</option>
//             {/* Добавьте другие языки по необходимости */}
//           </Form.Control>
//         </Form.Group>
//         <Form.Group controlId="formTranslationLanguage">
//           <Form.Label>Translation Language</Form.Label>
//           <Form.Control
//             as="select"
//             value={translationLanguage}
//             onChange={(e) => setTranslationLanguage(e.target.value)}
//           >
//             <option value="de-DE">Deutsch (Deutschland)</option>
//             <option value="ru-RU">Русский (Россия)</option>
//             <option value="en-US">English (US)</option>
//             <option value="es-ES">Spanish (Spain)</option>
//             <option value="fr-FR">French (France)</option>
//             {/* Добавьте другие языки по необходимости */}
//           </Form.Control>
//         </Form.Group>
//         <Form.Group controlId="formText">
//           <Form.Label>Enter text here</Form.Label>
//           <ReactQuill
//             value={text}
//             onChange={setText}
//             placeholder="Enter text here..."
//             style={{ height: '300px', marginBottom: '40px' }}
//           />
//         </Form.Group>
//         {error && <div className="alert alert-danger mt-3">{error}</div>}
//         <Button
//           variant="outline-primary"
//           type="submit"
//           disabled={loading}
//           className="mt-3"
//         >
//           {loading ? (
//             <>
//               <Spinner
//                 as="span"
//                 animation="border"
//                 size="sm"
//                 role="status"
//                 aria-hidden="true"
//               />
//               <span className="visually-hidden">Loading...</span>
//             </>
//           ) : (
//             "Analyze"
//           )}
//         </Button>
//       </Form>
//     </div>
//   );
// };

// export default AddTextPage;


// src/components/AddTextPage.js
// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { analyzeText, setArticleText, setArticleTextFromWeb } from "../redux/textsSlice";
// import { Form, Button, Spinner } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css'; // Импортируем стили Quill

// const modules = {
//     toolbar: [
//      // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
//       ['bold', 'italic', 'underline', 'strike'],
//       [{ 'color': [] }, { 'background': [] }],
//       ['link', 'image'],
//       ['clean'],
//       [{ 'size': ['small', false, 'large', 'huge'] }], // Размеры шрифта
//     ],
//   };
  
//   const formats = [
//    // 'header',
//     'bold', 'italic', 'underline', 'strike',
//     'color', 'background',
//     'font',
//     'link', 'image',
//     'size',
//   ];

// const AddTextPage = () => {
//   const [text, setText] = useState("");
//   const [title, setTitle] = useState("");
//   const [theme, setTheme] = useState("");
//   const [articleLanguage, setArticleLanguage] = useState("de-DE");
//   const [translationLanguage, setTranslationLanguage] = useState("ru-RU");
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState("");

//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { themes, articleText } = useSelector((state) => state.texts);
//   const user = useSelector((state) => state.texts.user);

//   useEffect(() => {
//     if (articleText) {
//       setText(articleText);
//     }
//   }, [articleText]);

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (!text || !title || !theme || !articleLanguage || !translationLanguage) {
//       setError("Please fill in all fields.");
//       return;
//     }
//     if (!user || user.role !== 'admin' && user.role !== 'editor') {
//       alert('You do not have permission to analyze text.');
//       return;
//     }
//     setLoading(true);
//     dispatch(analyzeText({ text, title, theme_id: theme, language: articleLanguage, translation_language: translationLanguage }))
//       .then(() => {
//         navigate(-1); // Вернуться на предыдущую страницу
//         dispatch(setArticleText(text));
//       })
//       .catch((error) => {
//         console.error("Error analyzing text:", error);
//       })
//       .finally(() => {
//         setLoading(false);
//         setError("");
//       });
//   };

//   return (
//     <div className="container mt-1">
//       <button onClick={() => navigate(-1)} className="btn btn-outline-secondary me-2">
//         <i className="bi bi-arrow-left"></i>
//       </button>
//       <h5 className="mt-2 mb-3">Add Text</h5>
//       <Form onSubmit={handleSubmit}>
//         <Form.Group controlId="formTitle">
//           <Form.Label>Title</Form.Label>
//           <Form.Control
//             type="text"
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             placeholder="Enter title here..."
//           />
//         </Form.Group>
//         <Form.Group controlId="formTheme">
//           <Form.Label>Theme</Form.Label>
//           <Form.Control
//             as="select"
//             value={theme}
//             onChange={(e) => setTheme(e.target.value)}
//           >
//             <option value="">Select theme</option>
//             {themes.map((theme) => (
//               <option key={theme.id} value={theme.id}>
//                 {theme.name}
//               </option>
//             ))}
//           </Form.Control>
//         </Form.Group>
//         <Form.Group controlId="formLanguage">
//           <Form.Label>Language</Form.Label>
//           <Form.Control
//             as="select"
//             value={articleLanguage}
//             onChange={(e) => setArticleLanguage(e.target.value)}
//           >
//             <option value="de-DE">Deutsch (Deutschland)</option>
//             <option value="ru-RU">Русский (Россия)</option>
//             <option value="en-US">English (US)</option>
//             <option value="es-ES">Spanish (Spain)</option>
//             <option value="fr-FR">French (France)</option>
//             {/* Добавьте другие языки по необходимости */}
//           </Form.Control>
//         </Form.Group>
//         <Form.Group controlId="formTranslationLanguage">
//           <Form.Label>Translation Language</Form.Label>
//           <Form.Control
//             as="select"
//             value={translationLanguage}
//             onChange={(e) => setTranslationLanguage(e.target.value)}
//           >
//             <option value="de-DE">Deutsch (Deutschland)</option>
//             <option value="ru-RU">Русский (Россия)</option>
//             <option value="en-US">English (US)</option>
//             <option value="es-ES">Spanish (Spain)</option>
//             <option value="fr-FR">French (France)</option>
//             {/* Добавьте другие языки по необходимости */}
//           </Form.Control>
//         </Form.Group>
//         <Form.Group controlId="formText">
//           <Form.Label>Enter text here</Form.Label>
//           <ReactQuill
//             value={text}
//             onChange={setText}
//             theme="snow"
//             modules={modules}
//             formats={formats}
//             placeholder="Enter text here..."
//             style={{ height: 'auto', marginBottom: '10px' }}
//           />
//         </Form.Group>
//         {error && <div className="alert alert-danger mt-3">{error}</div>}
//         <Button
//           variant="outline-primary"
//           type="submit"
//           disabled={loading}
//           className="mt-3"
//         >
//           {loading ? (
//             <>
//               <Spinner
//                 as="span"
//                 animation="border"
//                 size="sm"
//                 role="status"
//                 aria-hidden="true"
//               />
//               <span className="visually-hidden">Loading...</span>
//             </>
//           ) : (
//             "Analyze"
//           )}
//         </Button>
//       </Form>
//     </div>
//   );
// };

// export default AddTextPage;


// import React, { useState, useEffect, useRef } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { analyzeText, setArticleText, setArticleTextFromWeb } from "../redux/textsSlice";
// import { Form, Button, Spinner } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css'; // Импортируем стили Quill

// const modules = {
//   toolbar: [
//     ['bold', 'italic', 'underline', 'strike'],
//     [{ 'color': [] }, { 'background': [] }],
//     ['link', 'image'],
//     ['clean'],
//     [{ 'size': ['small', false, 'large', 'huge'] }], // Размеры шрифта
//   ],
// };

// const formats = [
//   'bold', 'italic', 'underline', 'strike',
//   'color', 'background',
//   'font',
//   'link', 'image',
//   'size',
// ];

// const AddTextPage = () => {
//   const [text, setText] = useState("");
//   const [title, setTitle] = useState("");
//   const [theme, setTheme] = useState("");
//   const [articleLanguage, setArticleLanguage] = useState("de-DE");
//   const [translationLanguage, setTranslationLanguage] = useState("ru-RU");
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState("");

//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { themes, articleText } = useSelector((state) => state.texts);
//   const user = useSelector((state) => state.texts.user);

//   const quillRef = useRef(null);

//   useEffect(() => {
//     if (articleText) {
//       setText(articleText);
//     }
//   }, [articleText]);

//   const handlePaste = (event) => {
//     event.preventDefault();

//     const clipboardData = event.clipboardData || window.clipboardData;
//     const pastedData = clipboardData.getData('text/html') || clipboardData.getData('text/plain');

//     const quill = quillRef.current.getEditor();
//     const range = quill.getSelection();
//     if (range) {
//       quill.clipboard.dangerouslyPasteHTML(range.index, pastedData);
//     }
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (!text || !title || !theme || !articleLanguage || !translationLanguage) {
//       setError("Please fill in all fields.");
//       return;
//     }
//     if (!user || user.role !== 'admin' && user.role !== 'editor') {
//       alert('You do not have permission to analyze text.');
//       return;
//     }
//     setLoading(true);
//     dispatch(analyzeText({ text, title, theme_id: theme, language: articleLanguage, translation_language: translationLanguage }))
//       .then(() => {
//         navigate(-1); // Вернуться на предыдущую страницу
//         dispatch(setArticleText(text));
//       })
//       .catch((error) => {
//         console.error("Error analyzing text:", error);
//       })
//       .finally(() => {
//         setLoading(false);
//         setError("");
//       });
//   };

//   return (
//     <div className="container mt-1">
//       <button onClick={() => navigate(-1)} className="btn btn-outline-secondary me-2">
//         <i className="bi bi-arrow-left"></i>
//       </button>
//       <h5 className="mt-2 mb-3">Add Text</h5>
//       <Form onSubmit={handleSubmit}>
//         <Form.Group controlId="formTitle">
//           <Form.Label>Title</Form.Label>
//           <Form.Control
//             type="text"
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             placeholder="Enter title here..."
//           />
//         </Form.Group>
//         <Form.Group controlId="formTheme">
//           <Form.Label>Theme</Form.Label>
//           <Form.Control
//             as="select"
//             value={theme}
//             onChange={(e) => setTheme(e.target.value)}
//           >
//             <option value="">Select theme</option>
//             {themes.map((theme) => (
//               <option key={theme.id} value={theme.id}>
//                 {theme.name}
//               </option>
//             ))}
//           </Form.Control>
//         </Form.Group>
//         <Form.Group controlId="formLanguage">
//           <Form.Label>Language</Form.Label>
//           <Form.Control
//             as="select"
//             value={articleLanguage}
//             onChange={(e) => setArticleLanguage(e.target.value)}
//           >
//             <option value="de-DE">Deutsch (Deutschland)</option>
//             <option value="ru-RU">Русский (Россия)</option>
//             <option value="en-US">English (US)</option>
//             <option value="es-ES">Spanish (Spain)</option>
//             <option value="fr-FR">French (France)</option>
//             {/* Добавьте другие языки по необходимости */}
//           </Form.Control>
//         </Form.Group>
//         <Form.Group controlId="formTranslationLanguage">
//           <Form.Label>Translation Language</Form.Label>
//           <Form.Control
//             as="select"
//             value={translationLanguage}
//             onChange={(e) => setTranslationLanguage(e.target.value)}
//           >
//             <option value="de-DE">Deutsch (Deutschland)</option>
//             <option value="ru-RU">Русский (Россия)</option>
//             <option value="en-US">English (US)</option>
//             <option value="es-ES">Spanish (Spain)</option>
//             <option value="fr-FR">French (France)</option>
//             {/* Добавьте другие языки по необходимости */}
//           </Form.Control>
//         </Form.Group>
//         <Form.Group controlId="formText">
//           <Form.Label>Enter text here</Form.Label>
//           <ReactQuill
//             ref={quillRef}
//             value={text}
//             onChange={setText}
//             theme="snow"
//             modules={modules}
//             formats={formats}
//             placeholder="Enter text here..."
//             style={{ height: 'auto', marginBottom: '10px' }}
//             onPaste={handlePaste}
//           />
//         </Form.Group>
//         {error && <div className="alert alert-danger mt-3">{error}</div>}
//         <Button
//           variant="outline-primary"
//           type="submit"
//           disabled={loading}
//           className="mt-3"
//         >
//           {loading ? (
//             <>
//               <Spinner
//                 as="span"
//                 animation="border"
//                 size="sm"
//                 role="status"
//                 aria-hidden="true"
//               />
//               <span className="visually-hidden">Loading...</span>
//             </>
//           ) : (
//             "Analyze"
//           )}
//         </Button>
//       </Form>
//     </div>
//   );
// };

// export default AddTextPage;


import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { analyzeText, setArticleText, setArticleTextFromWeb } from "../redux/textsSlice";
import { Form, Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Импортируем стили Quill

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'color': [] }, { 'background': [] }],
    ['link', 'image'],
    ['clean'],
    [{ 'size': ['small', false, 'large', 'huge'] }], // Размеры шрифта
  ],
};

const formats = [
  'bold', 'italic', 'underline', 'strike',
  'color', 'background',
  'font',
  'link', 'image',
  'size',
];

const AddTextPage = () => {
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [theme, setTheme] = useState("");
  const [articleLanguage, setArticleLanguage] = useState("de-DE");
  const [translationLanguage, setTranslationLanguage] = useState("ru-RU");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { themes, articleText } = useSelector((state) => state.texts);
  const user = useSelector((state) => state.texts.user);

  const quillRef = useRef(null);

  useEffect(() => {
    if (articleText) {
      setText(articleText);
    }
  }, [articleText]);

  const handlePaste = (event) => {
    event.preventDefault();

    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('text/html') || clipboardData.getData('text/plain');

    const quill = quillRef.current.getEditor();
    const range = quill.getSelection();
    if (range) {
      quill.clipboard.dangerouslyPasteHTML(range.index, pastedData);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!text || !title || !theme || !articleLanguage || !translationLanguage) {
      setError("Please fill in all fields.");
      return;
    }
    if (!user || user.role !== 'admin' && user.role !== 'editor') {
      alert('You do not have permission to analyze text.');
      return;
    }
    setLoading(true);
    dispatch(analyzeText({ text, title, theme_id: theme, language: articleLanguage, translation_language: translationLanguage }))
      .then(() => {
        navigate(-1); // Вернуться на предыдущую страницу
        dispatch(setArticleText(text));
      })
      .catch((error) => {
        console.error("Error analyzing text:", error);
      })
      .finally(() => {
        setLoading(false);
        setError("");
      });
  };

  return (
    <div className="container mt-1">
      <button onClick={() => navigate(-1)} className="btn btn-outline-secondary me-2">
        <i className="bi bi-arrow-left"></i>
      </button>
      <h5 className="mt-2 mb-3">Add Text</h5>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formTitle">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter title here..."
          />
        </Form.Group>
        <Form.Group controlId="formTheme">
          <Form.Label>Theme</Form.Label>
          <Form.Control
            as="select"
            value={theme}
            onChange={(e) => setTheme(e.target.value)}
          >
            <option value="">Select theme</option>
            {themes.map((theme) => (
              <option key={theme.id} value={theme.id}>
                {theme.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formLanguage">
          <Form.Label>Language</Form.Label>
          <Form.Control
            as="select"
            value={articleLanguage}
            onChange={(e) => setArticleLanguage(e.target.value)}
          >
            <option value="de-DE">Deutsch (Deutschland)</option>
            <option value="ru-RU">Русский (Россия)</option>
            <option value="en-US">English (US)</option>
            <option value="es-ES">Spanish (Spain)</option>
            <option value="fr-FR">French (France)</option>
            {/* Добавьте другие языки по необходимости */}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formTranslationLanguage">
          <Form.Label>Translation Language</Form.Label>
          <Form.Control
            as="select"
            value={translationLanguage}
            onChange={(e) => setTranslationLanguage(e.target.value)}
          >
            <option value="de-DE">Deutsch (Deutschland)</option>
            <option value="ru-RU">Русский (Россия)</option>
            <option value="en-US">English (US)</option>
            <option value="es-ES">Spanish (Spain)</option>
            <option value="fr-FR">French (France)</option>
            {/* Добавьте другие языки по необходимости */}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formText">
          <Form.Label>Enter text here</Form.Label>
          <ReactQuill
            ref={quillRef}
            value={text}
            onChange={setText}
            theme="snow"
            modules={modules}
            formats={formats}
            placeholder="Enter text here..."
            style={{ height: 'auto', marginBottom: '10px' }}
            onPaste={handlePaste}
          />
        </Form.Group>
        {error && <div className="alert alert-danger mt-3">{error}</div>}
        <Button
          variant="outline-primary"
          type="submit"
          disabled={loading}
          className="mt-3"
        >
          {loading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </>
          ) : (
            "Analyze"
          )}
        </Button>
      </Form>
    </div>
  );
};

export default AddTextPage;