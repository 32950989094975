// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   analyzeText,
//   createTheme,
//   deleteTheme,
//   setArticleText,
//   fetchThemes,
//   fetchArticles,
//   setArticleTextFromWeb, // Добавляем экшен
// } from "../redux/textsSlice";
// import { Modal, Button, Form, Spinner, ButtonGroup } from "react-bootstrap";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import {
//   Menu,
//   Item,
//   Separator,
//   Submenu,
//   useContextMenu,
// } from "react-contexify";
// import api from "../axios";
// import "react-contexify/dist/ReactContexify.css";
// import "./TextDivider.css";
// import ThemeManager from './ThemeManager';

// const TextAnalyzer = () => {
//   const [text, setText] = useState("");
//   const [title, setTitle] = useState("");
//   const [theme, setTheme] = useState("");
//   const [newThemeName, setNewThemeName] = useState("");
//   const [showThemeModal, setShowThemeModal] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [articleUrl, setArticleUrl] = useState("");
//   const [error, setError] = useState("");
//   const [showLoadArticleModal, setShowLoadArticleModal] = useState(false);
//   const [youtubeVideoId, setYoutubeVideoId] = useState("");
//   const [showLoadYoutubeTranscriptModal, setShowLoadYoutubeTranscriptModal] =
//     useState(false);
//   const [pdfFile, setPdfFile] = useState(null);
//   const [showLoadPdfModal, setShowLoadPdfModal] = useState(false);
//   const [pdfLoading, setPdfLoading] = useState(false);
//   const [imageFile, setImageFile] = useState(null);
//   const [showLoadImageModal, setShowLoadImageModal] = useState(false);
//   const [ocrLoading, setOcrLoading] = useState(false);
//   const [ocrLanguage, setOcrLanguage] = useState("de");
//   const [articleLanguage, setArticleLanguage] = useState("de-DE");
//   const [translationLanguage, setTranslationLanguage] = useState("ru-RU");

//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const { themes, articles } = useSelector((state) => state.texts);
//   const { courseId } = useParams();
//   const user = useSelector((state) => state.texts.user);

//   const location = useLocation();
//   const courseData = location.state;
//   const courseName = courseData.course_name;

//   useEffect(() => {
//     if (courseId) {
//       dispatch(fetchThemes(courseId));
//       dispatch(fetchArticles({ courseId, themeId: null }));
//     } else {
//       dispatch(fetchThemes());
//       dispatch(fetchArticles({ courseId: null, themeId: null }));
//     }
//   }, [dispatch, courseId]);

//   const handleShowText = (artId, artTitle, artText, artTheme, artTextLang, artTransLang) => {
//     const articleData = {
//       id: artId,
//       title: artTitle,
//       text: artText,
//       theme: artTheme,
//       text_language: artTextLang,
//       translation_language: artTransLang
//     };

//     navigate(`/articles/${artId}`, { state: articleData });
//   };

//   const handleCreateTheme = () => {
//     if (!user || user.role !== 'admin' && user.role !== 'editor') {
//       alert('You do not have permission to create themes.');
//       return;
//     }
//     dispatch(createTheme({ name: newThemeName, course_id: courseId })).then((response) => {
//       if (response.payload.success) {
//         dispatch(fetchThemes(courseId));
//         setNewThemeName("");
//       }
//     });
//   };

//   const handleDeleteTheme = (themeId) => {
//     if (!user || user.role !== 'admin' && user.role !== 'editor') {
//       alert('You do not have permission to delete themes.');
//       return;
//     }
//     dispatch(deleteTheme(themeId)).then(() => {
//       dispatch(fetchThemes(courseId));
//     });
//   };

//   const handleThemeChange = (e) => {
//     setTheme(e.target.value);
//     api
//       .get("/api/articles/", {
//         params: { theme_id: e.target.value },
//       })
//       .then((response) => dispatch(fetchArticles({ courseId, themeId: e.target.value })))
//       .catch((error) => console.error("Error fetching articles:", error));
//   };

//   const handleLoadArticle = async () => {
//     if (!user || user.role !== 'admin' && user.role !== 'editor') {
//       alert('You do not have permission to load articles.');
//       return;
//     }
//     setLoading(true);
//     setError("");

//     try {
//       const encodedUrl = btoa(articleUrl);
//       const response = await api.post("/api/parse-article/", {
//         url: encodedUrl,
//       });
//       const articleText = response.data.text;
//       dispatch(setArticleTextFromWeb(articleText)); // Сохраняем текст статьи в Redux
//       navigate("/add-text"); // Переход на страницу добавления текста
//     } catch (err) {
//       setError("Failed to load article. Please check the URL and try again.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleLoadYoutubeTranscript = async () => {
//     if (!user || user.role !== 'admin' && user.role !== 'editor') {
//       alert('You do not have permission to load YouTube transcripts.');
//       return;
//     }
//     setLoading(true);
//     setError("");

//     try {
//       const encodedVideoId = btoa(youtubeVideoId);
//       const response = await api.post("/api/get-youtube-transcript/", {
//         video_id: encodedVideoId,
//       });
//       const transcriptText = response.data.text;
//       dispatch(setArticleTextFromWeb(transcriptText)); // Сохраняем текст статьи в Redux
//       navigate("/add-text"); // Переход на страницу добавления текста
//     } catch (err) {
//       setError(
//         "Failed to load transcript. Please check the video ID and try again."
//       );
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleFileUpload = async (e) => {
//     if (!user || user.role !== 'admin' && user.role !== 'editor') {
//       alert('You do not have permission to upload PDFs.');
//       return;
//     }
//     const file = e.target.files[0];
//     if (file) {
//       setPdfFile(file);
//       setPdfLoading(true);
//       const formData = new FormData();
//       formData.append("file", file);

//       try {
//         const response = await api.post("/api/upload-pdf/", formData, {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         });
//         const pdfText = response.data.text;
//         dispatch(setArticleTextFromWeb(pdfText)); // Сохраняем текст статьи в Redux
//         navigate("/add-text"); // Переход на страницу добавления текста
//       } catch (err) {
//         setError("Failed to load PDF. Please try again.");
//       } finally {
//         setPdfLoading(false);
//       }
//     }
//   };

//   const handleDeleteArticle = async (articleId) => {
//     if (!user || user.role !== 'admin' && user.role !== 'editor') {
//       alert('You do not have permission to delete articles.');
//       return;
//     }
//     try {
//       await api.delete(`/api/articles/${articleId}/`);
//       dispatch(fetchArticles({ courseId, themeId: theme }));
//     } catch (error) {
//       console.error("Error deleting article:", error);
//     }
//   };

//   const handleImageUpload = async (e) => {
//     if (!user || user.role !== 'admin' && user.role !== 'editor') {
//       alert('You do not have permission to upload images.');
//       return;
//     }
//     const file = e.target.files[0];
//     if (file) {
//       setImageFile(file);
//       setOcrLoading(true);
//       const formData = new FormData();
//       formData.append("image", file);
//       formData.append("language", ocrLanguage);

//       try {
//         const response = await api.post("/api/upload-image/", formData, {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         });
//         const imageText = response.data.text;
//         dispatch(setArticleTextFromWeb(imageText)); // Сохраняем текст статьи в Redux
//         navigate("/add-text"); // Переход на страницу добавления текста
//       } catch (err) {
//         setError("Failed to extract text from image. Please try again.");
//       } finally {
//         setOcrLoading(false);
//       }
//     }
//   };

//   const { show } = useContextMenu({
//     id: "articleMenu",
//   });

//   const displayMenu = (e, articleId) => {
//     e.preventDefault();
//     show({
//       event: e,
//       props: {
//         articleId,
//       },
//     });
//   };

//   return (
//     <div className="container mt-1">
//       <button onClick={() => navigate(-1)} className="btn btn-outline-secondary me-2">
//         <i className="bi bi-arrow-left"></i>
//       </button>
//       <h5 className="mt-2 mb-3">Course: {courseName}</h5>
//       <div class="d-flex justify-content-center">
//         <ButtonGroup className="mb-3">
//           <Button
//             variant="outline-secondary"
//             onClick={() => setShowThemeModal(true)}
//             className="me-2 mb-3"
//           >
//             <i class="bi bi-globe2"></i> Themes
//           </Button>

//           <Button
//             variant="outline-secondary"
//             onClick={() => navigate("/user-expressions")}
//             className="me-2 mb-3"
//           >
//             <i className="bi bi-book"></i> Expressions
//           </Button>
//         </ButtonGroup>
//       </div>

//       <div class="d-flex justify-content-center">
//         <ButtonGroup className="mb-3">
//           <Button
//             variant="outline-primary"
//             onClick={() => navigate("/add-text")}
//             className="me-2"
//           >
//             <i className="bi bi-plus-circle"></i> Text
//           </Button>
//           <Button
//             variant="outline-success"
//             onClick={() => setShowLoadArticleModal(true)}
//             className="me-2"
//           >
//             <i className="bi bi-download"></i> Web
//           </Button>

//           <Button variant="outline-warning" onClick={() => setShowLoadPdfModal(true)}>
//             <i className="bi bi-file-earmark-pdf"></i> PDF
//           </Button>
//           <Button variant="outline-secondary" onClick={() => setShowLoadImageModal(true)} className="ms-2">
//             <i className="bi bi-image"></i> Image
//           </Button>
//         </ButtonGroup>
//       </div>
//       <div className="form-group">
//         <label>Theme</label>
//         <select
//           className="form-control form-select mb-3"
//           value={theme}
//           onChange={handleThemeChange}
//         >
//           <option value="">All Themes</option>
//           {themes.map((theme) => (
//             <option key={theme.id} value={theme.id}>
//               {theme.name}
//             </option>
//           ))}
//         </select>
//       </div>

//       <Modal show={showThemeModal} onHide={() => setShowThemeModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Управление темами</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <ThemeManager
//             themes={themes}
//             onThemeCreated={(newTheme) => dispatch(fetchThemes(courseId))}
//             onThemeDeleted={(themeId) => dispatch(fetchThemes(courseId))}
//             courseId={courseId}
//           />
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="outline-secondary" onClick={() => setShowThemeModal(false)}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       <Modal
//         show={showLoadArticleModal}
//         onHide={() => setShowLoadArticleModal(false)}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Load Article</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form>
//             <Form.Group controlId="formArticleUrl">
//               <Form.Label>Enter Article URL</Form.Label>
//               <Form.Control
//                 type="text"
//                 value={articleUrl}
//                 onChange={(e) => setArticleUrl(e.target.value)}
//                 placeholder="https://www.tagesschau.de/..."
//               />
//             </Form.Group>
//           </Form>
//           {error && <div className="alert alert-danger mt-3">{error}</div>}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button
//             variant="outline-secondary"
//             onClick={() => setShowLoadArticleModal(false)}
//           >
//             Close
//           </Button>
//           <Button
//             variant="outline-primary"
//             onClick={handleLoadArticle}
//             disabled={loading}
//           >
//             {loading ? (
//               <>
//                 <Spinner
//                   as="span"
//                   animation="border"
//                   size="sm"
//                   role="status"
//                   aria-hidden="true"
//                 />
//                 <span className="visually-hidden">Loading...</span>
//               </>
//             ) : (
//               "Load Article"
//             )}
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       <Modal
//         show={showLoadYoutubeTranscriptModal}
//         onHide={() => setShowLoadYoutubeTranscriptModal(false)}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Load from YouTube</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form>
//             <Form.Group controlId="formYoutubeVideoId">
//               <Form.Label>Enter YouTube Video ID</Form.Label>
//               <Form.Control
//                 type="text"
//                 value={youtubeVideoId}
//                 onChange={(e) => setYoutubeVideoId(e.target.value)}
//                 placeholder="e.g., dQw4w9WgXcQ"
//               />
//             </Form.Group>
//           </Form>
//           {error && <div className="alert alert-danger mt-3">{error}</div>}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button
//             variant="outline-secondary"
//             onClick={() => setShowLoadYoutubeTranscriptModal(false)}
//           >
//             Close
//           </Button>
//           <Button
//             variant="outline-primary"
//             onClick={handleLoadYoutubeTranscript}
//             disabled={loading}
//           >
//             {loading ? (
//               <>
//                 <Spinner
//                   as="span"
//                   animation="border"
//                   size="sm"
//                   role="status"
//                   aria-hidden="true"
//                 />
//                 <span className="visually-hidden">Loading...</span>
//               </>
//             ) : (
//               "Load Transcript"
//             )}
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       <Modal show={showLoadPdfModal} onHide={() => setShowLoadPdfModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Load PDF</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form>
//             <Form.Group controlId="formPdfFile">
//               <Form.Label>Upload PDF File</Form.Label>
//               <Form.Control
//                 type="file"
//                 accept="application/pdf"
//                 onChange={handleFileUpload}
//               />
//             </Form.Group>
//           </Form>
//           {error && <div className="alert alert-danger mt-3">{error}</div>}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button
//             variant="outline-secondary"
//             onClick={() => setShowLoadPdfModal(false)}
//           >
//             Close
//           </Button>
//           <Button
//             variant="outline-primary"
//             onClick={handleFileUpload}
//             disabled={pdfLoading}
//           >
//             {pdfLoading ? (
//               <>
//                 <Spinner
//                   as="span"
//                   animation="border"
//                   size="sm"
//                   role="status"
//                   aria-hidden="true"
//                 />
//                 <span className="visually-hidden">Loading...</span>
//               </>
//             ) : (
//               "Upload PDF"
//             )}
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       <Modal show={showLoadImageModal} onHide={() => setShowLoadImageModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Load Image</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form>
//             <Form.Group controlId="formImageFile">
//               <Form.Label>Upload Image File</Form.Label>
//               <Form.Control
//                 type="file"
//                 accept="image/*"
//                 onChange={handleImageUpload}
//               />
//             </Form.Group>
//             <Form.Group controlId="formOcrLanguage">
//               <Form.Label>Select OCR Language</Form.Label>
//               <Form.Control
//                 as="select"
//                 value={ocrLanguage}
//                 onChange={(e) => setOcrLanguage(e.target.value)}
//               >
//                 <option value="en">English</option>
//                 <option value="ru">Russian</option>
//                 <option value="de">German</option>
//                 {/* Добавьте другие языки по необходимости */}
//               </Form.Control>
//             </Form.Group>
//           </Form>
//           {error && <div className="alert alert-danger mt-3">{error}</div>}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button
//             variant="outline-secondary"
//             onClick={() => setShowLoadImageModal(false)}
//           >
//             Close
//           </Button>
//           <Button
//             variant="outline-primary"
//             onClick={handleImageUpload}
//             disabled={ocrLoading}
//           >
//             {ocrLoading ? (
//               <>
//                 <Spinner
//                   as="span"
//                   animation="border"
//                   size="sm"
//                   role="status"
//                   aria-hidden="true"
//                 />
//                 <span className="visually-hidden">Loading...</span>
//               </>
//             ) : (
//               "Extract Text"
//             )}
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       <h6 class="text-divider">
//         <span>Articles</span>
//       </h6>
//       <ol className="list-group list-group-numbered">
//         {articles.map((article) => (
//           <li
//             key={article.id}
//             className="list-group-item d-flex justify-content-between align-items-start"
//             onClick={() =>
//               handleShowText(
//                 article.id,
//                 article.name,
//                 article.text,
//                 article.theme,
//                 article.text_language,
//                 article.translation_language,
//               )
//             }
//             onContextMenu={(e) => displayMenu(e, article.id)}
//             style={{ cursor: "pointer" }}
//           >
//             <div class="ms-2 me-auto">
//               <div class="fw-bold">{article.name}</div>
//             </div>
//           </li>
//         ))}
//       </ol>

//       <Menu id="articleMenu">
//         <Item
//           onClick={({ event, props }) => handleDeleteArticle(props.articleId)}
//         >
//           Delete Article
//         </Item>
//       </Menu>
//     </div>
//   );
// };

// export default TextAnalyzer;


// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   analyzeText,
//   createTheme,
//   deleteTheme,
//   setArticleText,
//   fetchThemes,
//   fetchArticles,
//   setArticleTextFromWeb, // Добавляем экшен
// } from "../redux/textsSlice";
// import { Modal, Button, Form, Spinner, ButtonGroup } from "react-bootstrap";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import {
//   Menu,
//   Item,
//   Separator,
//   Submenu,
//   useContextMenu,
// } from "react-contexify";
// import api from "../axios";
// import "react-contexify/dist/ReactContexify.css";
// import "./TextDivider.css";
// import ThemeManager from './ThemeManager';
// import { DndProvider, useDrag, useDrop } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';

// const ItemTypes = {
//   THEME: 'theme',
//   ARTICLE: 'article',
// };

// const Theme = ({ theme, articles, handleShowText, isExpanded, toggleExpand }) => {
//   const [{ isDragging }, drag] = useDrag({
//     type: ItemTypes.THEME,
//     item: { id: theme.id, type: ItemTypes.THEME },
//     collect: (monitor) => ({
//       isDragging: monitor.isDragging(),
//     }),
//   });

//   return (
//     <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
//       <div className="theme" onClick={toggleExpand}>
//         <h5>{theme.name}</h5>
//         {isExpanded && (
//           <ul>
//             {articles.map((article) => (
//               <Article key={article.id} article={article} handleShowText={handleShowText} />
//             ))}
//           </ul>
//         )}
//       </div>
//     </div>
//   );
// };

// const Article = ({ article, handleShowText }) => {
//   const [{ isDragging }, drag] = useDrag({
//     type: ItemTypes.ARTICLE,
//     item: { id: article.id, type: ItemTypes.ARTICLE },
//     collect: (monitor) => ({
//       isDragging: monitor.isDragging(),
//     }),
//   });

//   return (
//     <li
//       ref={drag}
//       style={{ opacity: isDragging ? 0.5 : 1, cursor: 'pointer' }}
//       onClick={() => handleShowText(article.id, article.name, article.text, article.theme, article.text_language, article.translation_language)}
//     >
//       {article.name}
//     </li>
//   );
// };

// const TextAnalyzer = () => {
//   const [text, setText] = useState("");
//   const [title, setTitle] = useState("");
//   const [theme, setTheme] = useState("");
//   const [newThemeName, setNewThemeName] = useState("");
//   const [showThemeModal, setShowThemeModal] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [articleUrl, setArticleUrl] = useState("");
//   const [error, setError] = useState("");
//   const [showLoadArticleModal, setShowLoadArticleModal] = useState(false);
//   const [youtubeVideoId, setYoutubeVideoId] = useState("");
//   const [showLoadYoutubeTranscriptModal, setShowLoadYoutubeTranscriptModal] =
//     useState(false);
//   const [pdfFile, setPdfFile] = useState(null);
//   const [showLoadPdfModal, setShowLoadPdfModal] = useState(false);
//   const [pdfLoading, setPdfLoading] = useState(false);
//   const [imageFile, setImageFile] = useState(null);
//   const [showLoadImageModal, setShowLoadImageModal] = useState(false);
//   const [ocrLoading, setOcrLoading] = useState(false);
//   const [ocrLanguage, setOcrLanguage] = useState("de");
//   const [articleLanguage, setArticleLanguage] = useState("de-DE");
//   const [translationLanguage, setTranslationLanguage] = useState("ru-RU");
//   const [expandedThemes, setExpandedThemes] = useState({});

//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const { themes, articles } = useSelector((state) => state.texts);
//   const { courseId } = useParams();
//   const user = useSelector((state) => state.texts.user);

//   const location = useLocation();
//   const courseData = location.state;
//   const courseName = courseData.course_name;

//   useEffect(() => {
//     if (courseId) {
//       dispatch(fetchThemes(courseId));
//       dispatch(fetchArticles({ courseId, themeId: null }));
//     } else {
//       dispatch(fetchThemes());
//       dispatch(fetchArticles({ courseId: null, themeId: null }));
//     }
//   }, [dispatch, courseId]);

//   const handleShowText = (artId, artTitle, artText, artTheme, artTextLang, artTransLang) => {
//     const articleData = {
//       id: artId,
//       title: artTitle,
//       text: artText,
//       theme: artTheme,
//       text_language: artTextLang,
//       translation_language: artTransLang
//     };

//     navigate(`/articles/${artId}`, { state: articleData });
//   };

//   const handleCreateTheme = () => {
//     if (!user || user.role !== 'admin' && user.role !== 'editor') {
//       alert('You do not have permission to create themes.');
//       return;
//     }
//     dispatch(createTheme({ name: newThemeName, course_id: courseId })).then((response) => {
//       if (response.payload.success) {
//         dispatch(fetchThemes(courseId));
//         setNewThemeName("");
//       }
//     });
//   };

//   const handleDeleteTheme = (themeId) => {
//     if (!user || user.role !== 'admin' && user.role !== 'editor') {
//       alert('You do not have permission to delete themes.');
//       return;
//     }
//     dispatch(deleteTheme(themeId)).then(() => {
//       dispatch(fetchThemes(courseId));
//     });
//   };

//   const handleThemeChange = (e) => {
//     setTheme(e.target.value);
//     api
//       .get("/api/articles/", {
//         params: { theme_id: e.target.value },
//       })
//       .then((response) => dispatch(fetchArticles({ courseId, themeId: e.target.value })))
//       .catch((error) => console.error("Error fetching articles:", error));
//   };

//   const handleLoadArticle = async () => {
//     if (!user || user.role !== 'admin' && user.role !== 'editor') {
//       alert('You do not have permission to load articles.');
//       return;
//     }
//     setLoading(true);
//     setError("");

//     try {
//       const encodedUrl = btoa(articleUrl);
//       const response = await api.post("/api/parse-article/", {
//         url: encodedUrl,
//       });
//       const articleText = response.data.text;
//       dispatch(setArticleTextFromWeb(articleText)); // Сохраняем текст статьи в Redux
//       navigate("/add-text"); // Переход на страницу добавления текста
//     } catch (err) {
//       setError("Failed to load article. Please check the URL and try again.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleLoadYoutubeTranscript = async () => {
//     if (!user || user.role !== 'admin' && user.role !== 'editor') {
//       alert('You do not have permission to load YouTube transcripts.');
//       return;
//     }
//     setLoading(true);
//     setError("");

//     try {
//       const encodedVideoId = btoa(youtubeVideoId);
//       const response = await api.post("/api/get-youtube-transcript/", {
//         video_id: encodedVideoId,
//       });
//       const transcriptText = response.data.text;
//       dispatch(setArticleTextFromWeb(transcriptText)); // Сохраняем текст статьи в Redux
//       navigate("/add-text"); // Переход на страницу добавления текста
//     } catch (err) {
//       setError(
//         "Failed to load transcript. Please check the video ID and try again."
//       );
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleFileUpload = async (e) => {
//     if (!user || user.role !== 'admin' && user.role !== 'editor') {
//       alert('You do not have permission to upload PDFs.');
//       return;
//     }
//     const file = e.target.files[0];
//     if (file) {
//       setPdfFile(file);
//       setPdfLoading(true);
//       const formData = new FormData();
//       formData.append("file", file);

//       try {
//         const response = await api.post("/api/upload-pdf/", formData, {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         });
//         const pdfText = response.data.text;
//         dispatch(setArticleTextFromWeb(pdfText)); // Сохраняем текст статьи в Redux
//         navigate("/add-text"); // Переход на страницу добавления текста
//       } catch (err) {
//         setError("Failed to load PDF. Please try again.");
//       } finally {
//         setPdfLoading(false);
//       }
//     }
//   };

//   const handleDeleteArticle = async (articleId) => {
//     if (!user || user.role !== 'admin' && user.role !== 'editor') {
//       alert('You do not have permission to delete articles.');
//       return;
//     }
//     try {
//       await api.delete(`/api/articles/${articleId}/`);
//       dispatch(fetchArticles({ courseId, themeId: theme }));
//     } catch (error) {
//       console.error("Error deleting article:", error);
//     }
//   };

//   const handleImageUpload = async (e) => {
//     if (!user || user.role !== 'admin' && user.role !== 'editor') {
//       alert('You do not have permission to upload images.');
//       return;
//     }
//     const file = e.target.files[0];
//     if (file) {
//       setImageFile(file);
//       setOcrLoading(true);
//       const formData = new FormData();
//       formData.append("image", file);
//       formData.append("language", ocrLanguage);

//       try {
//         const response = await api.post("/api/upload-image/", formData, {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         });
//         const imageText = response.data.text;
//         dispatch(setArticleTextFromWeb(imageText)); // Сохраняем текст статьи в Redux
//         navigate("/add-text"); // Переход на страницу добавления текста
//       } catch (err) {
//         setError("Failed to extract text from image. Please try again.");
//       } finally {
//         setOcrLoading(false);
//       }
//     }
//   };

//   const { show } = useContextMenu({
//     id: "articleMenu",
//   });

//   const displayMenu = (e, articleId) => {
//     e.preventDefault();
//     show({
//       event: e,
//       props: {
//         articleId,
//       },
//     });
//   };

//   const toggleExpand = (themeId) => {
//     setExpandedThemes((prev) => ({
//       ...prev,
//       [themeId]: !prev[themeId],
//     }));
//   };

//   return (
//     <div className="container mt-1">
//       <button onClick={() => navigate(-1)} className="btn btn-outline-secondary me-2">
//         <i className="bi bi-arrow-left"></i>
//       </button>
//       <h5 className="mt-2 mb-3">Course: {courseName}</h5>
//       <div class="d-flex justify-content-center">
//         <ButtonGroup className="mb-3">
//           <Button
//             variant="outline-secondary"
//             onClick={() => setShowThemeModal(true)}
//             className="me-2 mb-3"
//           >
//             <i class="bi bi-globe2"></i> Themes
//           </Button>

//           <Button
//             variant="outline-secondary"
//             onClick={() => navigate("/user-expressions")}
//             className="me-2 mb-3"
//           >
//             <i className="bi bi-book"></i> Expressions
//           </Button>
//         </ButtonGroup>
//       </div>

//       <div class="d-flex justify-content-center">
//         <ButtonGroup className="mb-3">
//           <Button
//             variant="outline-primary"
//             onClick={() => navigate("/add-text")}
//             className="me-2"
//           >
//             <i className="bi bi-plus-circle"></i> Text
//           </Button>
//           <Button
//             variant="outline-success"
//             onClick={() => setShowLoadArticleModal(true)}
//             className="me-2"
//           >
//             <i className="bi bi-download"></i> Web
//           </Button>

//           <Button variant="outline-warning" onClick={() => setShowLoadPdfModal(true)}>
//             <i className="bi bi-file-earmark-pdf"></i> PDF
//           </Button>
//           <Button variant="outline-secondary" onClick={() => setShowLoadImageModal(true)} className="ms-2">
//             <i className="bi bi-image"></i> Image
//           </Button>
//         </ButtonGroup>
//       </div>

//       <Modal show={showThemeModal} onHide={() => setShowThemeModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Управление темами</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <ThemeManager
//             themes={themes}
//             onThemeCreated={(newTheme) => dispatch(fetchThemes(courseId))}
//             onThemeDeleted={(themeId) => dispatch(fetchThemes(courseId))}
//             courseId={courseId}
//           />
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="outline-secondary" onClick={() => setShowThemeModal(false)}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       <Modal
//         show={showLoadArticleModal}
//         onHide={() => setShowLoadArticleModal(false)}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Load Article</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form>
//             <Form.Group controlId="formArticleUrl">
//               <Form.Label>Enter Article URL</Form.Label>
//               <Form.Control
//                 type="text"
//                 value={articleUrl}
//                 onChange={(e) => setArticleUrl(e.target.value)}
//                 placeholder="https://www.tagesschau.de/..."
//               />
//             </Form.Group>
//           </Form>
//           {error && <div className="alert alert-danger mt-3">{error}</div>}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button
//             variant="outline-secondary"
//             onClick={() => setShowLoadArticleModal(false)}
//           >
//             Close
//           </Button>
//           <Button
//             variant="outline-primary"
//             onClick={handleLoadArticle}
//             disabled={loading}
//           >
//             {loading ? (
//               <>
//                 <Spinner
//                   as="span"
//                   animation="border"
//                   size="sm"
//                   role="status"
//                   aria-hidden="true"
//                 />
//                 <span className="visually-hidden">Loading...</span>
//               </>
//             ) : (
//               "Load Article"
//             )}
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       <Modal
//         show={showLoadYoutubeTranscriptModal}
//         onHide={() => setShowLoadYoutubeTranscriptModal(false)}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Load from YouTube</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form>
//             <Form.Group controlId="formYoutubeVideoId">
//               <Form.Label>Enter YouTube Video ID</Form.Label>
//               <Form.Control
//                 type="text"
//                 value={youtubeVideoId}
//                 onChange={(e) => setYoutubeVideoId(e.target.value)}
//                 placeholder="e.g., dQw4w9WgXcQ"
//               />
//             </Form.Group>
//           </Form>
//           {error && <div className="alert alert-danger mt-3">{error}</div>}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button
//             variant="outline-secondary"
//             onClick={() => setShowLoadYoutubeTranscriptModal(false)}
//           >
//             Close
//           </Button>
//           <Button
//             variant="outline-primary"
//             onClick={handleLoadYoutubeTranscript}
//             disabled={loading}
//           >
//             {loading ? (
//               <>
//                 <Spinner
//                   as="span"
//                   animation="border"
//                   size="sm"
//                   role="status"
//                   aria-hidden="true"
//                 />
//                 <span className="visually-hidden">Loading...</span>
//               </>
//             ) : (
//               "Load Transcript"
//             )}
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       <Modal show={showLoadPdfModal} onHide={() => setShowLoadPdfModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Load PDF</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form>
//             <Form.Group controlId="formPdfFile">
//               <Form.Label>Upload PDF File</Form.Label>
//               <Form.Control
//                 type="file"
//                 accept="application/pdf"
//                 onChange={handleFileUpload}
//               />
//             </Form.Group>
//           </Form>
//           {error && <div className="alert alert-danger mt-3">{error}</div>}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button
//             variant="outline-secondary"
//             onClick={() => setShowLoadPdfModal(false)}
//           >
//             Close
//           </Button>
//           <Button
//             variant="outline-primary"
//             onClick={handleFileUpload}
//             disabled={pdfLoading}
//           >
//             {pdfLoading ? (
//               <>
//                 <Spinner
//                   as="span"
//                   animation="border"
//                   size="sm"
//                   role="status"
//                   aria-hidden="true"
//                 />
//                 <span className="visually-hidden">Loading...</span>
//               </>
//             ) : (
//               "Upload PDF"
//             )}
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       <Modal show={showLoadImageModal} onHide={() => setShowLoadImageModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Load Image</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form>
//             <Form.Group controlId="formImageFile">
//               <Form.Label>Upload Image File</Form.Label>
//               <Form.Control
//                 type="file"
//                 accept="image/*"
//                 onChange={handleImageUpload}
//               />
//             </Form.Group>
//             <Form.Group controlId="formOcrLanguage">
//               <Form.Label>Select OCR Language</Form.Label>
//               <Form.Control
//                 as="select"
//                 value={ocrLanguage}
//                 onChange={(e) => setOcrLanguage(e.target.value)}
//               >
//                 <option value="en">English</option>
//                 <option value="ru">Russian</option>
//                 <option value="de">German</option>
//                 {/* Добавьте другие языки по необходимости */}
//               </Form.Control>
//             </Form.Group>
//           </Form>
//           {error && <div className="alert alert-danger mt-3">{error}</div>}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button
//             variant="outline-secondary"
//             onClick={() => setShowLoadImageModal(false)}
//           >
//             Close
//           </Button>
//           <Button
//             variant="outline-primary"
//             onClick={handleImageUpload}
//             disabled={ocrLoading}
//           >
//             {ocrLoading ? (
//               <>
//                 <Spinner
//                   as="span"
//                   animation="border"
//                   size="sm"
//                   role="status"
//                   aria-hidden="true"
//                 />
//                 <span className="visually-hidden">Loading...</span>
//               </>
//             ) : (
//               "Extract Text"
//             )}
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       <h6 class="text-divider">
//         <span>Articles</span>
//       </h6>
//       <DndProvider backend={HTML5Backend}>
//         <div className="tree">
//           {themes.map((theme) => (
//             <Theme
//               key={theme.id}
//               theme={theme}
//               articles={articles.filter((article) => article.theme === theme.id)}
//               handleShowText={handleShowText}
//               isExpanded={expandedThemes[theme.id]}
//               toggleExpand={() => toggleExpand(theme.id)}
//             />
//           ))}
//         </div>
//       </DndProvider>

//       <Menu id="articleMenu">
//         <Item
//           onClick={({ event, props }) => handleDeleteArticle(props.articleId)}
//         >
//           Delete Article
//         </Item>
//       </Menu>
//     </div>
//   );
// };

// export default TextAnalyzer;






import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  analyzeText,
  createTheme,
  deleteTheme,
  setArticleText,
  fetchThemes,
  fetchArticles,
  setArticleTextFromWeb, // Добавляем экшен
} from "../redux/textsSlice";
import { Modal, Button, Form, Spinner, ButtonGroup, Accordion, ListGroup } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Menu,
  Item,
  Separator,
  Submenu,
  useContextMenu,
} from "react-contexify";
import api from "../axios";
import "react-contexify/dist/ReactContexify.css";
import "./TextDivider.css";
import ThemeManager from './ThemeManager';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const ItemTypes = {
  THEME: 'theme',
  ARTICLE: 'article',
};

const Theme = ({ theme, articles, handleShowText, handleDeleteArticle, isExpanded, toggleExpand }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.THEME,
    item: { id: theme.id, type: ItemTypes.THEME },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <Accordion.Item eventKey={theme.id.toString()}>
        <Accordion.Header onClick={toggleExpand}>{theme.name}</Accordion.Header>
        <Accordion.Body>
          <ListGroup variant="flush">
            {articles.map((article) => (
              <Article key={article.id} article={article} handleShowText={handleShowText} handleDeleteArticle={handleDeleteArticle} />
            ))}
          </ListGroup>
        </Accordion.Body>
      </Accordion.Item>
    </div>
  );
};

const Article = ({ article, handleShowText, handleDeleteArticle }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ARTICLE,
    item: { id: article.id, type: ItemTypes.ARTICLE },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <ListGroup.Item
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1, cursor: 'pointer' }}
      onClick={() => handleShowText(article.id, article.name, article.text, article.theme, article.text_language, article.translation_language)}
    >
      <div className="d-flex justify-content-between align-items-center">
        <span>{article.name}</span>
        <Button
          variant="link"
          className="text-danger"
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteArticle(article.id);
          }}
        >
          <i className="bi bi-x"></i>
        </Button>
      </div>
    </ListGroup.Item>
  );
};

const TextAnalyzer = () => {
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [theme, setTheme] = useState("");
  const [newThemeName, setNewThemeName] = useState("");
  const [showThemeModal, setShowThemeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [articleUrl, setArticleUrl] = useState("");
  const [error, setError] = useState("");
  const [showLoadArticleModal, setShowLoadArticleModal] = useState(false);
  const [youtubeVideoId, setYoutubeVideoId] = useState("");
  const [showLoadYoutubeTranscriptModal, setShowLoadYoutubeTranscriptModal] =
    useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [showLoadPdfModal, setShowLoadPdfModal] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [showLoadImageModal, setShowLoadImageModal] = useState(false);
  const [ocrLoading, setOcrLoading] = useState(false);
  const [ocrLanguage, setOcrLanguage] = useState("de");
  const [articleLanguage, setArticleLanguage] = useState("de-DE");
  const [translationLanguage, setTranslationLanguage] = useState("ru-RU");
  const [expandedThemes, setExpandedThemes] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { themes, articles } = useSelector((state) => state.texts);
  const { courseId } = useParams();
  const user = useSelector((state) => state.texts.user);

  const location = useLocation();
  const courseData = location.state;
  const courseName = courseData.course_name;

  useEffect(() => {
    if (courseId) {
      dispatch(fetchThemes(courseId));
      dispatch(fetchArticles({ courseId, themeId: null }));
    } else {
      dispatch(fetchThemes());
      dispatch(fetchArticles({ courseId: null, themeId: null }));
    }
  }, [dispatch, courseId]);

  const handleShowText = (artId, artTitle, artText, artTheme, artTextLang, artTransLang) => {
    const articleData = {
      id: artId,
      title: artTitle,
      text: artText,
      theme: artTheme,
      text_language: artTextLang,
      translation_language: artTransLang
    };

    navigate(`/articles/${artId}`, { state: articleData });
  };

  const handleCreateTheme = () => {
    if (!user || user.role !== 'admin' && user.role !== 'editor') {
      alert('You do not have permission to create themes.');
      return;
    }
    dispatch(createTheme({ name: newThemeName, course_id: courseId })).then((response) => {
      if (response.payload.success) {
        dispatch(fetchThemes(courseId));
        setNewThemeName("");
      }
    });
  };

  const handleDeleteTheme = (themeId) => {
    if (!user || user.role !== 'admin' && user.role !== 'editor') {
      alert('You do not have permission to delete themes.');
      return;
    }
    dispatch(deleteTheme(themeId)).then(() => {
      dispatch(fetchThemes(courseId));
    });
  };

  const handleThemeChange = (e) => {
    setTheme(e.target.value);
    api
      .get("/api/articles/", {
        params: { theme_id: e.target.value },
      })
      .then((response) => dispatch(fetchArticles({ courseId, themeId: e.target.value })))
      .catch((error) => console.error("Error fetching articles:", error));
  };

  const handleLoadArticle = async () => {
    if (!user || user.role !== 'admin' && user.role !== 'editor') {
      alert('You do not have permission to load articles.');
      return;
    }
    setLoading(true);
    setError("");

    try {
      const encodedUrl = btoa(articleUrl);
      const response = await api.post("/api/parse-article/", {
        url: encodedUrl,
      });
      const articleText = response.data.text;
      dispatch(setArticleTextFromWeb(articleText)); // Сохраняем текст статьи в Redux
      navigate("/add-text"); // Переход на страницу добавления текста
    } catch (err) {
      setError("Failed to load article. Please check the URL and try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleLoadYoutubeTranscript = async () => {
    if (!user || user.role !== 'admin' && user.role !== 'editor') {
      alert('You do not have permission to load YouTube transcripts.');
      return;
    }
    setLoading(true);
    setError("");

    try {
      const encodedVideoId = btoa(youtubeVideoId);
      const response = await api.post("/api/get-youtube-transcript/", {
        video_id: encodedVideoId,
      });
      const transcriptText = response.data.text;
      dispatch(setArticleTextFromWeb(transcriptText)); // Сохраняем текст статьи в Redux
      navigate("/add-text"); // Переход на страницу добавления текста
    } catch (err) {
      setError(
        "Failed to load transcript. Please check the video ID and try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (e) => {
    if (!user || user.role !== 'admin' && user.role !== 'editor') {
      alert('You do not have permission to upload PDFs.');
      return;
    }
    const file = e.target.files[0];
    if (file) {
      setPdfFile(file);
      setPdfLoading(true);
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await api.post("/api/upload-pdf/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        const pdfText = response.data.text;
        dispatch(setArticleTextFromWeb(pdfText)); // Сохраняем текст статьи в Redux
        navigate("/add-text"); // Переход на страницу добавления текста
      } catch (err) {
        setError("Failed to load PDF. Please try again.");
      } finally {
        setPdfLoading(false);
      }
    }
  };

  const handleDeleteArticle = async (articleId) => {
    if (!user || user.role !== 'admin' && user.role !== 'editor') {
      alert('You do not have permission to delete articles.');
      return;
    }
    try {
      await api.delete(`/api/articles/${articleId}/`);
      dispatch(fetchArticles({ courseId, themeId: theme }));
    } catch (error) {
      console.error("Error deleting article:", error);
    }
  };

  const handleImageUpload = async (e) => {
    if (!user || user.role !== 'admin' && user.role !== 'editor') {
      alert('You do not have permission to upload images.');
      return;
    }
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setOcrLoading(true);
      const formData = new FormData();
      formData.append("image", file);
      formData.append("language", ocrLanguage);

      try {
        const response = await api.post("/api/upload-image/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        const imageText = response.data.text;
        dispatch(setArticleTextFromWeb(imageText)); // Сохраняем текст статьи в Redux
        navigate("/add-text"); // Переход на страницу добавления текста
      } catch (err) {
        setError("Failed to extract text from image. Please try again.");
      } finally {
        setOcrLoading(false);
      }
    }
  };

  const toggleExpand = (themeId) => {
    setExpandedThemes((prev) => ({
      ...prev,
      [themeId]: !prev[themeId],
    }));
  };

  return (
    <div className="container mt-1">
      <button onClick={() => navigate(-1)} className="btn btn-outline-secondary me-2">
        <i className="bi bi-arrow-left"></i>
      </button>
      <h5 className="mt-2 mb-3">Course: {courseName}</h5>
      <div class="d-flex justify-content-center">
        <ButtonGroup className="mb-3">
          <Button
            variant="outline-secondary"
            onClick={() => setShowThemeModal(true)}
            className="me-2 mb-3"
          >
            <i class="bi bi-globe2"></i> Themes
          </Button>

          <Button
            variant="outline-secondary"
            onClick={() => navigate("/user-expressions")}
            className="me-2 mb-3"
          >
            <i className="bi bi-book"></i> Expressions
          </Button>
        </ButtonGroup>
      </div>

      <div class="d-flex justify-content-center">
        <ButtonGroup className="mb-3">
          <Button
            variant="outline-primary"
            onClick={() => navigate("/add-text")}
            className="me-2"
          >
            <i className="bi bi-plus-circle"></i> Text
          </Button>
          <Button
            variant="outline-success"
            onClick={() => setShowLoadArticleModal(true)}
            className="me-2"
          >
            <i className="bi bi-download"></i> Web
          </Button>

          <Button variant="outline-warning" onClick={() => setShowLoadPdfModal(true)}>
            <i className="bi bi-file-earmark-pdf"></i> PDF
          </Button>
          <Button variant="outline-secondary" onClick={() => setShowLoadImageModal(true)} className="ms-2">
            <i className="bi bi-image"></i> Image
          </Button>
        </ButtonGroup>
      </div>

      <Modal show={showThemeModal} onHide={() => setShowThemeModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Theme Manager</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ThemeManager
            themes={themes}
            onThemeCreated={(newTheme) => dispatch(fetchThemes(courseId))}
            onThemeDeleted={(themeId) => dispatch(fetchThemes(courseId))}
            courseId={courseId}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowThemeModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showLoadArticleModal}
        onHide={() => setShowLoadArticleModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Load Article</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formArticleUrl">
              <Form.Label>Enter Article URL</Form.Label>
              <Form.Control
                type="text"
                value={articleUrl}
                onChange={(e) => setArticleUrl(e.target.value)}
                placeholder="https://www.tagesschau.de/..."
              />
            </Form.Group>
          </Form>
          {error && <div className="alert alert-danger mt-3">{error}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => setShowLoadArticleModal(false)}
          >
            Close
          </Button>
          <Button
            variant="outline-primary"
            onClick={handleLoadArticle}
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              "Load Article"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showLoadYoutubeTranscriptModal}
        onHide={() => setShowLoadYoutubeTranscriptModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Load from YouTube</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formYoutubeVideoId">
              <Form.Label>Enter YouTube Video ID</Form.Label>
              <Form.Control
                type="text"
                value={youtubeVideoId}
                onChange={(e) => setYoutubeVideoId(e.target.value)}
                placeholder="e.g., dQw4w9WgXcQ"
              />
            </Form.Group>
          </Form>
          {error && <div className="alert alert-danger mt-3">{error}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => setShowLoadYoutubeTranscriptModal(false)}
          >
            Close
          </Button>
          <Button
            variant="outline-primary"
            onClick={handleLoadYoutubeTranscript}
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              "Load Transcript"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showLoadPdfModal} onHide={() => setShowLoadPdfModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Load PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formPdfFile">
              <Form.Label>Upload PDF File</Form.Label>
              <Form.Control
                type="file"
                accept="application/pdf"
                onChange={handleFileUpload}
              />
            </Form.Group>
          </Form>
          {error && <div className="alert alert-danger mt-3">{error}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => setShowLoadPdfModal(false)}
          >
            Close
          </Button>
          <Button
            variant="outline-primary"
            onClick={handleFileUpload}
            disabled={pdfLoading}
          >
            {pdfLoading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              "Upload PDF"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showLoadImageModal} onHide={() => setShowLoadImageModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Load Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formImageFile">
              <Form.Label>Upload Image File</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
              />
            </Form.Group>
            <Form.Group controlId="formOcrLanguage">
              <Form.Label>Select OCR Language</Form.Label>
              <Form.Control
                as="select"
                value={ocrLanguage}
                onChange={(e) => setOcrLanguage(e.target.value)}
              >
                <option value="en">English</option>
                <option value="ru">Russian</option>
                <option value="de">German</option>
                {/* Добавьте другие языки по необходимости */}
              </Form.Control>
            </Form.Group>
          </Form>
          {error && <div className="alert alert-danger mt-3">{error}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => setShowLoadImageModal(false)}
          >
            Close
          </Button>
          <Button
            variant="outline-primary"
            onClick={handleImageUpload}
            disabled={ocrLoading}
          >
            {ocrLoading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              "Extract Text"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <h6 class="text-divider">
        <span>Articles</span>
      </h6>
      <DndProvider backend={HTML5Backend}>
        <Accordion className="accordion-flush">
          {themes.map((theme) => (
            <Theme
              key={theme.id}
              theme={theme}
              articles={articles.filter((article) => article.theme === theme.id)}
              handleShowText={handleShowText}
              handleDeleteArticle={handleDeleteArticle}
              isExpanded={expandedThemes[theme.id]}
              toggleExpand={() => toggleExpand(theme.id)}
            />
          ))}
        </Accordion>
      </DndProvider>
    </div>
  );
};

export default TextAnalyzer;