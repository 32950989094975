// import React from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { GoogleOAuthProvider } from '@react-oauth/google';
// import Navbar from './components/Navbar';
// import TextAnalyzer from './components/TextAnalyzer';
// import SentenceList from './components/SentenceList';
// import Register from './components/Register';
// import Login from './components/Login';
// import VerifyEmail from './components/VerifyEmail';
// import Dictionary from './components/Dictionary';
// import UserExpressionsList from './components/UserExpressionsList';
// import PasswordResetRequest from './components/PasswordResetRequest';
// import PasswordReset from './components/PasswordReset';
// import UserProfile from './components/UserProfile';
// import 'bootstrap-icons/font/bootstrap-icons.css';

// function App() {
//   const isAuthenticated = useSelector((state) => state.texts.isAuthenticated);

//   return (
//     <GoogleOAuthProvider clientId="648677942290-t2d34c5cuog5f0p34k8kl8jcj0goqseu.apps.googleusercontent.com">
//       <Router>
//         {isAuthenticated && <Navbar />}
//         <div className="container">
//           <Routes>
//             <Route path="/register" element={<Register />} />
//             <Route path="/login" element={<Login />} />
//             <Route path="/verify-email/:token" element={<VerifyEmail />} />
//             <Route
//               path="/"
//               element={
//                 isAuthenticated ? (
//                   <TextAnalyzer />
//                 ) : (
//                   <Navigate to="/login" replace />
//                 )
//               }
//             />
//             <Route path="/articles/:articleId" element={<SentenceList />} />
//             <Route path="/dictionary/:sentenceId" element={<Dictionary />} />
//             <Route path="/user-expressions" element={<UserExpressionsList />} />
//             <Route path="/password-reset-request" element={<PasswordResetRequest />} />
//             <Route path="/reset-password/:token" element={<PasswordReset />} />
//             <Route path="/profile" element={<UserProfile />} />
//           </Routes>
//         </div>
//       </Router>
//     </GoogleOAuthProvider>
//   );
// }

// export default App;


import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Navbar from './components/Navbar';
import TextAnalyzer from './components/TextAnalyzer';
import SentenceList from './components/SentenceList';
import Register from './components/Register';
import Login from './components/Login';
import VerifyEmail from './components/VerifyEmail';
import Dictionary from './components/Dictionary';
import UserExpressionsList from './components/UserExpressionsList';
import PasswordResetRequest from './components/PasswordResetRequest';
import PasswordReset from './components/PasswordReset';
import UserProfile from './components/UserProfile';
import HomePage from './components/HomePage'; // Импортируем компонент HomePage
import ManageCoursesPage from './components/ManageCoursesPage';
import AddTextPage from "./components/AddTextPage";
import 'bootstrap-icons/font/bootstrap-icons.css';

function App() {
  const isAuthenticated = useSelector((state) => state.texts.isAuthenticated);

  return (
    <GoogleOAuthProvider clientId="123.apps.googleusercontent.com">
      <Router>
        {isAuthenticated && <Navbar />}
        <div className="container">
          <Routes>
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/verify-email/:token" element={<VerifyEmail />} />
            <Route
              path="/"
              element={
                isAuthenticated ? (
                  <HomePage />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route path="/manage-courses" element={<ManageCoursesPage />} />
            <Route path="/articles/:articleId" element={<SentenceList />} />
            <Route path="/dictionary/:sentenceId" element={<Dictionary />} />
            <Route path="/user-expressions" element={<UserExpressionsList />} />
            <Route path="/password-reset-request" element={<PasswordResetRequest />} />
            <Route path="/reset-password/:token" element={<PasswordReset />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/text-analyzer/:courseId" element={<TextAnalyzer />} /> {/* Добавляем маршрут для TextAnalyzer с параметром courseId */}
            <Route path="/add-text" element={<AddTextPage />} />
          </Routes>
        </div>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
