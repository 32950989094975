
// import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { createTheme, deleteTheme } from '../redux/textsSlice';

// const ThemeManager = ({ themes, onThemeCreated, onThemeDeleted, courseId }) => {
//   const [newThemeName, setNewThemeName] = useState('');
//   const dispatch = useDispatch();
//   const user = useSelector((state) => state.texts.user); // Используем state.texts.user

//   const handleCreateTheme = () => {
//     if (!user || user.role !== 'admin' && user.role !== 'editor') {
//       alert('You do not have permission to create themes.');
//       return;
//     }

//     dispatch(createTheme({ name: newThemeName, course_id: courseId })).then((response) => {
//       if (response.payload.success) {
//         onThemeCreated(response.payload.data);
//         setNewThemeName('');
//       }
//     });
//   };

//   const handleDeleteTheme = (themeId) => {
//     if (!user || user.role !== 'admin' && user.role !== 'editor') {
//       alert('You do not have permission to delete themes.');
//       return;
//     }

//     dispatch(deleteTheme(themeId)).then(() => {
//       onThemeDeleted(themeId);
//     });
//   };

//   return (
//     <div className="container mt-5">
//       <h2>Manage Themes</h2>
//       <div className="form-group">
//         <label>New Theme</label>
//         <input
//           type="text"
//           className="form-control"
//           value={newThemeName}
//           onChange={(e) => setNewThemeName(e.target.value)}
//         />
//       </div>
//       <button onClick={handleCreateTheme} className="btn btn-primary mt-2">Create Theme</button>
//       <ul className="list-group mt-3">
//         {themes.map(theme => (
//           <li key={theme.id} className="list-group-item d-flex justify-content-between align-items-center">
//             {theme.name}
//             <button onClick={() => handleDeleteTheme(theme.id)} className="btn btn-danger">Delete</button>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default ThemeManager;



// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { createTheme, deleteTheme, fetchThemes } from '../redux/textsSlice';

// const ThemeManager = ({ courseId }) => {
//   const [newThemeName, setNewThemeName] = useState('');
//   const dispatch = useDispatch();
//   const user = useSelector((state) => state.texts.user); // Используем state.texts.user
//   const themes = useSelector((state) => state.texts.themes); // Получаем темы из состояния

//   useEffect(() => {
//     // Автоматически обновляем список тем при изменении courseId
//     dispatch(fetchThemes(courseId));
//   }, [dispatch, courseId]);

//   const handleCreateTheme = () => {
//     if (!user || user.role !== 'admin' && user.role !== 'editor') {
//       alert('You do not have permission to create themes.');
//       return;
//     }

//     dispatch(createTheme({ name: newThemeName, course_id: courseId })).then((response) => {
//       if (response.payload.success) {
//         setNewThemeName('');
//         dispatch(fetchThemes(courseId)); // Обновляем список тем после создания
//       }
//     });
//   };

//   const handleDeleteTheme = (themeId) => {
//     if (!user || user.role !== 'admin' && user.role !== 'editor') {
//       alert('You do not have permission to delete themes.');
//       return;
//     }

//     dispatch(deleteTheme(themeId)).then(() => {
//       dispatch(fetchThemes(courseId)); // Обновляем список тем после удаления
//     });
//   };

//   return (
//     <div className="container mt-1">
//       <h6>Course ID: {courseId}</h6>
//       <div className="form-group">
//         <label>New Theme</label>
//         <input
//           type="text"
//           className="form-control"
//           value={newThemeName}
//           onChange={(e) => setNewThemeName(e.target.value)}
//         />
//       </div>
//       <button onClick={handleCreateTheme} className="btn btn-primary mt-2">Create Theme</button>
//       <ul className="list-group mt-3">
//         {themes.map(theme => (
//           <li key={theme.id} className="list-group-item d-flex justify-content-between align-items-center">
//             {theme.name}
            
//               <button onClick={() => handleDeleteTheme(theme.id)} className="btn btn-danger">Delete</button>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default ThemeManager;


import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTheme, deleteTheme, fetchThemes } from '../redux/textsSlice';

const ThemeManager = ({ courseId }) => {
  const [newThemeName, setNewThemeName] = useState('');
  const dispatch = useDispatch();
  const user = useSelector((state) => state.texts.user);
  const themes = useSelector((state) => state.texts.themes);

  const handleCreateTheme = () => {
    if (!user || user.role !== 'admin' && user.role !== 'editor') {
      alert('You do not have permission to create themes.');
      return;
    }

    if (!newThemeName) {
      alert('Theme name is required.');
      return;
    }

    dispatch(createTheme({ name: newThemeName, course_id: courseId })).then(() => {
      dispatch(fetchThemes(courseId));
      setNewThemeName('');
    });
  };

  const handleDeleteTheme = (themeId) => {
    if (!user || user.role !== 'admin' && user.role !== 'editor') {
      alert('You do not have permission to delete themes.');
      return;
    }

    dispatch(deleteTheme(themeId)).then(() => {
      dispatch(fetchThemes(courseId));
    });
  };

  return (
    <div className="container mt-1">
      <div className="form-group">
        <label>New Theme</label>
        <input
          type="text"
          className="form-control"
          value={newThemeName}
          onChange={(e) => setNewThemeName(e.target.value)}
        />
      </div>
      <button onClick={handleCreateTheme} className="btn btn-outline-success mt-2">Create Theme</button>
      <ul className="list-group mt-3">
        {themes.map(theme => (
          <li key={theme.id} className="list-group-item d-flex justify-content-between align-items-center">
            {theme.name}
              <button onClick={() => handleDeleteTheme(theme.id)} className=" btn btn-outline-danger btn-sm"><i className="bi bi-x"></i></button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ThemeManager;