// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchUserProfile, setTextLanguage, setTranslationLanguage, setTextVoice, setTranslationVoice, saveUserSettings } from '../redux/textsSlice';
// import { useNavigate } from "react-router-dom";
// import api from "../axios";

// const UserProfile = () => {
//   const dispatch = useDispatch();
//   const user = useSelector(state => state.texts.user);
//   const textLanguage = useSelector(state => state.texts.textLanguage);
//   const translationLanguage = useSelector(state => state.texts.translationLanguage);
//   const textVoice = useSelector(state => state.texts.textVoice);
//   const translationVoice = useSelector(state => state.texts.translationVoice);
//   const navigate = useNavigate();

//   const [isWebView, setIsWebView] = useState(false);
//   const [textVoices, setTextVoices] = useState([]);
//   const [translationVoices, setTranslationVoices] = useState([]);
//   const [loadingVoices, setLoadingVoices] = useState(true);

//   useEffect(() => {
//     dispatch(fetchUserProfile());
//     setIsWebView(!!window.ReactNativeWebView);
//   }, [dispatch]);

//   useEffect(() => {
//     setLoadingVoices(true);
//     if (isWebView) {
//       // Отправляем сообщение в Expo-приложение для запроса списка голосов
//       window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'GET_VOICES', lang: textLanguage, target: 'text', comp: 'UserProfile' }));
//       window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'GET_VOICES', lang: translationLanguage, target: 'translation', comp: 'UserProfile' }));
//     } else {
//       // Если запущено в браузере, используем speechSynthesis
//       const loadVoices = () => {
//         if (window.speechSynthesis) {
//           const synth = window.speechSynthesis;
//           const availableVoices = synth.getVoices();
//           setTextVoices(availableVoices.filter(voice => voice.lang.startsWith(textLanguage.split('-')[0])));
//           setTranslationVoices(availableVoices.filter(voice => voice.lang.startsWith(translationLanguage.split('-')[0])));
//           setLoadingVoices(false);
//         } else {
//           console.error('SpeechSynthesis is not supported in this browser.');
//         }
//       };

//       if (window.speechSynthesis) {
//         window.speechSynthesis.onvoiceschanged = loadVoices;
//       } else {
//         console.error('SpeechSynthesis is not supported in this browser.');
//       }

//       // Задержка для загрузки голосов
//       setTimeout(loadVoices, 500);
//     }

//     // Обработчик сообщений от Expo-приложения
//     const handleMessage = (event) => {
//       try {
//         const data = event.data;
//         // Проверяем, что сообщение является JSON-строкой
//         if (data.startsWith('{') && data.endsWith('}')) {
//           const parsedData = JSON.parse(data);
//           if (parsedData.type === 'VOICES_RESPONSE') {
//             if (parsedData.target === 'text') {
//               setTextVoices(parsedData.voices);
//             } else if (parsedData.target === 'translation') {
//               setTranslationVoices(parsedData.voices);
//             }
//             setLoadingVoices(false);
//           }
//         }
//       } catch (error) {
//         console.error('Invalid JSON:', event.data);
//       }
//     };

//     // Добавляем обработчик сообщений
//     document.addEventListener('message', handleMessage);

//     // Удаляем обработчик при размонтировании компонента
//     return () => {
//       document.removeEventListener('message', handleMessage);
//     };
//   }, [textLanguage, translationLanguage, isWebView]);

//   useEffect(() => {
//     if (textVoices.length > 0) {
//       dispatch(setTextVoice(textVoices[0].name));
//     }
//   }, [textVoices, dispatch]);

//   useEffect(() => {
//     if (translationVoices.length > 0) {
//       dispatch(setTranslationVoice(translationVoices[0].name));
//     }
//   }, [translationVoices, dispatch]);

//   const handleTextLanguageChange = (e) => {
//     const language = e.target.value;
//     dispatch(setTextLanguage(language));
//     dispatch(saveUserSettings({ text_language: language }));
//     if (isWebView) {
//       window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'GET_VOICES', lang: language, target: 'text', comp: 'UserProfile' }));
//     } else {
//       const synth = window.speechSynthesis;
//       const availableVoices = synth.getVoices();
//       setTextVoices(availableVoices.filter(voice => voice.lang.startsWith(language.split('-')[0])));
//     }
//   };

//   const handleTranslationLanguageChange = (e) => {
//     const language = e.target.value;
//     dispatch(setTranslationLanguage(language));
//     dispatch(saveUserSettings({ translation_language: language }));
//     if (isWebView) {
//       window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'GET_VOICES', lang: language, target: 'translation', comp: 'UserProfile' }));
//     } else {
//       const synth = window.speechSynthesis;
//       const availableVoices = synth.getVoices();
//       setTranslationVoices(availableVoices.filter(voice => voice.lang.startsWith(language.split('-')[0])));
//     }
//   };

//   const handleTextVoiceChange = (e) => {
//     const voice = e.target.value;
//     dispatch(setTextVoice(voice));
//     dispatch(saveUserSettings({ text_voice: voice }));
//   };

//   const handleTranslationVoiceChange = (e) => {
//     const voice = e.target.value;
//     dispatch(setTranslationVoice(voice));
//     dispatch(saveUserSettings({ translation_voice: voice }));
//   };

//   if (!user) return <p>Loading...</p>;

//   return (
//     <div className="container mt-3">
//       <div className="row">
//         <div className="col-12">
//           <button onClick={() => navigate(-1)} className="btn btn-outline-secondary me-2">
//             <i className="bi bi-arrow-left"></i>
//           </button>
//           <h2>Profile</h2>
//           <p><strong>Username:</strong> {user.username}</p>
//           <p><strong>Email:</strong> {user.email}</p>
//         </div>
//       </div>

//       <div className="row mt-4">
//         <div className="col-12 col-md-6">
//           <h3>Select Text Language</h3>
//           <select value={textLanguage} onChange={handleTextLanguageChange} className="form-select">
//             <option value="de-DE">Deutsch (Deutschland)</option>
//             <option value="ru-RU">Русский (Россия)</option>
//             <option value="en-US">English (US)</option>
//             <option value="es-ES">Spanish (Spain)</option>
//             <option value="fr-FR">French (France)</option>
//             {/* Add more languages as needed */}
//           </select>
//         </div>

//         <div className="col-12 col-md-6">
//           <h3>Select Translation Language</h3>
//           <select value={translationLanguage} onChange={handleTranslationLanguageChange} className="form-select">
//             <option value="de-DE">Deutsch (Deutschland)</option>
//             <option value="ru-RU">Русский (Россия)</option>
//             <option value="en-US">English (US)</option>
//             <option value="es-ES">Spanish (Spain)</option>
//             <option value="fr-FR">French (France)</option>
//             {/* Add more languages as needed */}
//           </select>
//         </div>
//       </div>

//       <div className="row mt-4">
//         <div className="col-12 col-md-6">
//           <h3>Select Text Voice</h3>
//           {loadingVoices ? (
//             <p>Loading voices...</p>
//           ) : (
//             <select value={textVoice} onChange={handleTextVoiceChange} className="form-select">
//               {textVoices.map(voice => (
//                 <option key={voice.identifier} value={voice.name}>
//                   {voice.name}
//                 </option>
//               ))}
//             </select>
//           )}
//         </div>

//         <div className="col-12 col-md-6">
//           <h3>Select Translation Voice</h3>
//           {loadingVoices ? (
//             <p>Loading voices...</p>
//           ) : (
//             <select value={translationVoice} onChange={handleTranslationVoiceChange} className="form-select">
//               {translationVoices.map(voice => (
//                 <option key={voice.identifier} value={voice.name}>
//                   {voice.name}
//                 </option>
//               ))}
//             </select>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default UserProfile;


import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserProfile, setTextLanguage, setTranslationLanguage, setTextVoice, setTranslationVoice, saveUserSettings } from '../redux/textsSlice';
import { useNavigate } from "react-router-dom";
import api from "../axios";

const UserProfile = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.texts.user);
  const textLanguage = useSelector(state => state.texts.textLanguage);
  const translationLanguage = useSelector(state => state.texts.translationLanguage);
  const textVoice = useSelector(state => state.texts.textVoice);
  const translationVoice = useSelector(state => state.texts.translationVoice);
  const navigate = useNavigate();

  const [isWebView, setIsWebView] = useState(false);
  const [textVoices, setTextVoices] = useState([]);
  const [translationVoices, setTranslationVoices] = useState([]);
  const [loadingVoices, setLoadingVoices] = useState(true);

  useEffect(() => {
    dispatch(fetchUserProfile());
    setIsWebView(!!window.ReactNativeWebView);
  }, [dispatch]);

  useEffect(() => {
    setLoadingVoices(true);
    if (isWebView) {
      // Отправляем сообщение в Expo-приложение для запроса списка голосов
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'GET_VOICES', lang: textLanguage, target: 'text', comp: 'UserProfile' }));
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'GET_VOICES', lang: translationLanguage, target: 'translation', comp: 'UserProfile' }));
    } else {
      // Если запущено в браузере, используем speechSynthesis
      const loadVoices = () => {
        if (window.speechSynthesis) {
          const synth = window.speechSynthesis;
          const availableVoices = synth.getVoices();
          setTextVoices(availableVoices.filter(voice => voice.lang.startsWith(textLanguage.split('-')[0])));
          setTranslationVoices(availableVoices.filter(voice => voice.lang.startsWith(translationLanguage.split('-')[0])));
          setLoadingVoices(false);
        } else {
          console.error('SpeechSynthesis is not supported in this browser.');
        }
      };

      if (window.speechSynthesis) {
        window.speechSynthesis.onvoiceschanged = loadVoices;
      } else {
        console.error('SpeechSynthesis is not supported in this browser.');
      }

      // Задержка для загрузки голосов
      setTimeout(loadVoices, 500);
    }

    // Обработчик сообщений от Expo-приложения
    const handleMessage = (event) => {
      try {
        const data = event.data;
        // Проверяем, что сообщение является JSON-строкой
        if (data.startsWith('{') && data.endsWith('}')) {
          const parsedData = JSON.parse(data);
          if (parsedData.type === 'VOICES_RESPONSE') {
            if (parsedData.target === 'text') {
              setTextVoices(parsedData.voices);
            } else if (parsedData.target === 'translation') {
              setTranslationVoices(parsedData.voices);
            }
            setLoadingVoices(false);
          }
        }
      } catch (error) {
        console.error('Invalid JSON:', event.data);
      }
    };

    // Добавляем обработчик сообщений
    document.addEventListener('message', handleMessage);

    // Удаляем обработчик при размонтировании компонента
    return () => {
      document.removeEventListener('message', handleMessage);
    };
  }, [textLanguage, translationLanguage, isWebView]);

  useEffect(() => {
    if (textVoices.length > 0) {
      dispatch(setTextVoice(textVoices[0].name));
    }
  }, [textVoices, dispatch]);

  useEffect(() => {
    if (translationVoices.length > 0) {
      dispatch(setTranslationVoice(translationVoices[0].name));
    }
  }, [translationVoices, dispatch]);

  const handleTextLanguageChange = (e) => {
    const language = e.target.value;
    dispatch(setTextLanguage(language));
    dispatch(saveUserSettings({ text_language: language }));
    if (isWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'GET_VOICES', lang: language, target: 'text', comp: 'UserProfile' }));
    } else {
      const synth = window.speechSynthesis;
      const availableVoices = synth.getVoices();
      setTextVoices(availableVoices.filter(voice => voice.lang.startsWith(language.split('-')[0])));
    }
  };

  const handleTranslationLanguageChange = (e) => {
    const language = e.target.value;
    dispatch(setTranslationLanguage(language));
    dispatch(saveUserSettings({ translation_language: language }));
    if (isWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'GET_VOICES', lang: language, target: 'translation', comp: 'UserProfile' }));
    } else {
      const synth = window.speechSynthesis;
      const availableVoices = synth.getVoices();
      setTranslationVoices(availableVoices.filter(voice => voice.lang.startsWith(language.split('-')[0])));
    }
  };

  const handleTextVoiceChange = (e) => {
    const voice = e.target.value;
    dispatch(setTextVoice(voice));
    dispatch(saveUserSettings({ text_voice: voice }));
  };

  const handleTranslationVoiceChange = (e) => {
    const voice = e.target.value;
    dispatch(setTranslationVoice(voice));
    dispatch(saveUserSettings({ translation_voice: voice }));
  };

  if (!user) return <p>Loading...</p>;

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-12">
          <button onClick={() => navigate(-1)} className="btn btn-outline-secondary me-2">
            <i className="bi bi-arrow-left"></i>
          </button>
          <h2>Profile</h2>
          <p><strong>Username:</strong> {user.username}</p>
          <p><strong>Email:</strong> {user.email}</p>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12 col-md-6">
          <h3>Select Text Language</h3>
          <select value={textLanguage} onChange={handleTextLanguageChange} className="form-select">
            <option value="de-DE">Deutsch (Deutschland)</option>
            <option value="ru-RU">Русский (Россия)</option>
            <option value="en-US">English (US)</option>
            <option value="es-ES">Spanish (Spain)</option>
            <option value="fr-FR">French (France)</option>
            {/* Add more languages as needed */}
          </select>
        </div>

        <div className="col-12 col-md-6">
          <h3>Select Translation Language</h3>
          <select value={translationLanguage} onChange={handleTranslationLanguageChange} className="form-select">
            <option value="de-DE">Deutsch (Deutschland)</option>
            <option value="ru-RU">Русский (Россия)</option>
            <option value="en-US">English (US)</option>
            <option value="es-ES">Spanish (Spain)</option>
            <option value="fr-FR">French (France)</option>
            {/* Add more languages as needed */}
          </select>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12 col-md-6">
          <h3>Select Text Voice</h3>
          {loadingVoices ? (
            <p>Loading voices...</p>
          ) : (
            <select value={textVoice} onChange={handleTextVoiceChange} className="form-select">
              {textVoices.map(voice => (
                <option key={voice.name} value={voice.name}>
                  {voice.name}
                </option>
              ))}
            </select>
          )}
        </div>

        <div className="col-12 col-md-6">
          <h3>Select Translation Voice</h3>
          {loadingVoices ? (
            <p>Loading voices...</p>
          ) : (
            <select value={translationVoice} onChange={handleTranslationVoiceChange} className="form-select">
              {translationVoices.map(voice => (
                <option key={voice.name} value={voice.name}>
                  {voice.name}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;