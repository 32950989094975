// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchCourses } from '../redux/textsSlice';
// import { useNavigate } from 'react-router-dom';
// import { Button, Card } from 'react-bootstrap'; // Импортируем компоненты Bootstrap
// import 'bootstrap/dist/css/bootstrap.min.css'; // Импортируем стили Bootstrap
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';

// const HomePage = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { courses, loading, error, user } = useSelector(state => ({
//     courses: state.texts.courses,
//     loading: state.texts.loading,
//     error: state.texts.error,
//     user: state.texts.user,
//   }));

//   useEffect(() => {
//     dispatch(fetchCourses());
//   }, [dispatch]);

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error: {error}</p>;

//   const handleCourseClick = (courseId, courseName) => {

//     const courseData = {
//       course_id: courseId,
//       course_name: courseName,
//     };

//     navigate(`/text-analyzer/${courseId}`, { state: courseData });
//   };

//   const handleManageCourses = () => {
//     navigate('/manage-courses');
//   };

//   const responsive = {
//     superLargeDesktop: {
//       breakpoint: { max: 4000, min: 3000 },
//       items: 5
//     },
//     desktop: {
//       breakpoint: { max: 3000, min: 1024 },
//       items: 3
//     },
//     tablet: {
//       breakpoint: { max: 1024, min: 464 },
//       items: 2
//     },
//     mobile: {
//       breakpoint: { max: 464, min: 0 },
//       items: 1
//     }
//   };

//   return (
//     <div className="container mt-2">
//       <Button variant="outline-primary" onClick={handleManageCourses} className="mb-1">Courses</Button>

//       <div style={{ display: 'flex', flexDirection: 'column', height: '80vh' }}>
//         <h6 className="text-divider"><span>Public courses</span></h6>
//         <div style={{ flex: 1, overflowX: 'auto', marginBottom: '1rem' }}>
//           <Carousel responsive={responsive} arrows={true} showDots={true}>
//             {courses.filter(course => course.is_public).map(course => (
//               <Card key={course.id} onClick={() => handleCourseClick(course.id, course.name)} style={{ cursor: 'pointer', margin: '0 10px', height: '100%' }}>
//                 <Card.Body>
//                   <Card.Title>{course.name}</Card.Title>
//                   <Card.Text>{course.description}</Card.Text>
//                 </Card.Body>
//               </Card>
//             ))}
//           </Carousel>
//         </div>
//         <h6 className="text-divider"><span>Your courses</span></h6>
//         <div style={{ flex: 1, overflowX: 'auto' }}>
//           <Carousel responsive={responsive} arrows={true} showDots={true}>
//             {courses.filter(course => course.created_by === user.id).map(course => (
//               <Card key={course.id} onClick={() => handleCourseClick(course.id, course.name)} style={{ cursor: 'pointer', margin: '0 10px', height: '100%' }}>
//                 <Card.Body>
//                   <Card.Title>{course.name}</Card.Title>
//                   <Card.Text>{course.description}</Card.Text>
//                 </Card.Body>
//               </Card>
//             ))}
//           </Carousel>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HomePage;


// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchCourses } from '../redux/textsSlice';
// import { useNavigate } from 'react-router-dom';
// import { Button, Card } from 'react-bootstrap'; // Импортируем компоненты Bootstrap
// import 'bootstrap/dist/css/bootstrap.min.css'; // Импортируем стили Bootstrap
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
// import './HomePage.css'; // Импортируем стили для карусели

// const HomePage = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { courses, loading, error, user } = useSelector(state => ({
//     courses: state.texts.courses,
//     loading: state.texts.loading,
//     error: state.texts.error,
//     user: state.texts.user,
//   }));

//   useEffect(() => {
//     dispatch(fetchCourses());
//   }, [dispatch]);

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error: {error}</p>;

//   const handleCourseClick = (courseId, courseName) => {
//     const courseData = {
//       course_id: courseId,
//       course_name: courseName,
//     };

//     navigate(`/text-analyzer/${courseId}`, { state: courseData });
//   };

//   const handleManageCourses = () => {
//     navigate('/manage-courses');
//   };

//   const responsive = {
//     superLargeDesktop: {
//       breakpoint: { max: 4000, min: 3000 },
//       items: 5
//     },
//     desktop: {
//       breakpoint: { max: 3000, min: 1024 },
//       items: 3
//     },
//     tablet: {
//       breakpoint: { max: 1024, min: 464 },
//       items: 2
//     },
//     mobile: {
//       breakpoint: { max: 464, min: 0 },
//       items: 1
//     }
//   };

//   return (
//     <div className="container mt-2">
//       <Button variant="outline-primary" onClick={handleManageCourses} className="mb-1">Courses</Button>

//       <div style={{ display: 'flex', flexDirection: 'column', height: '80vh' }}>
//         <h6 className="text-divider"><span>Public courses</span></h6>
//         <div style={{ flex: 1, overflowX: 'auto', marginBottom: '1rem' }}>
//           <Carousel responsive={responsive} arrows={true} showDots={true} className="carousel-container">
//             {courses.filter(course => course.is_public).map(course => (
//               <Card key={course.id} onClick={() => handleCourseClick(course.id, course.name)} className="carousel-item" style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
//                 <Card.Body style={{ flexGrow: 1 }}>
//                   <Card.Title>{course.name}</Card.Title>
//                   <Card.Text>{course.description}</Card.Text>
//                 </Card.Body>
//               </Card>
//             ))}
//           </Carousel>
//         </div>
//         <h6 className="text-divider"><span>Your courses</span></h6>
//         <div style={{ flex: 1, overflowX: 'auto' }}>
//           <Carousel responsive={responsive} arrows={true} showDots={true} className="carousel-container">
//             {courses.filter(course => course.created_by === user.id).map(course => (
//               <Card key={course.id} onClick={() => handleCourseClick(course.id, course.name)} className="carousel-item " style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
//                 <Card.Body style={{ flexGrow: 1 }}>
//                   <Card.Title>{course.name}</Card.Title>
//                   <Card.Text>{course.description}</Card.Text>
//                 </Card.Body>
//               </Card>
//             ))}
//           </Carousel>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HomePage;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourses } from '../redux/textsSlice';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap'; // Импортируем компоненты Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'; // Импортируем стили Bootstrap
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './HomePage.css'; // Импортируем стили для карусели

const PanelHeading = ({ title }) => (
  <div className="panel-heading">
    <h5>{title}</h5>
  </div>
);

const Panel = ({ title, description, onClick }) => (
  <div className="panel" onClick={onClick}>
    <PanelHeading title={title} />
    <div className="panel-body">
      <p>{description}</p>
    </div>
  </div>
);

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courses, loading, error, user } = useSelector(state => ({
    courses: state.texts.courses,
    loading: state.texts.loading,
    error: state.texts.error,
    user: state.texts.user,
  }));

  useEffect(() => {
    dispatch(fetchCourses());
  }, [dispatch]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const handleCourseClick = (courseId, courseName) => {
    const courseData = {
      course_id: courseId,
      course_name: courseName,
    };

    navigate(`/text-analyzer/${courseId}`, { state: courseData });
  };

  const handleManageCourses = () => {
    navigate('/manage-courses');
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div className="container mt-2">
      <Button variant="outline-primary" onClick={handleManageCourses} className="mb-1">Courses</Button>

      <div style={{ display: 'flex', flexDirection: 'column', height: '80vh' }}>
        <h6 className="text-divider"><span>Public courses</span></h6>
        <div style={{ flex: 1, overflowX: 'auto', marginBottom: '1rem' }}>
          <Carousel responsive={responsive} arrows={true} showDots={true} className="carousel-container">
            {courses.filter(course => course.is_public).map(course => (
              <Panel
                key={course.id}
                title={course.name}
                description={course.description}
                onClick={() => handleCourseClick(course.id, course.name)}
              />
            ))}
          </Carousel>
        </div>
        <h6 className="text-divider"><span>Your courses</span></h6>
        <div style={{ flex: 1, overflowX: 'auto' }}>
          <Carousel responsive={responsive} arrows={true} showDots={true} className="carousel-container">
            {courses.filter(course => course.created_by === user.id).map(course => (
              <Panel
                key={course.id}
                title={course.name}
                description={course.description}
                onClick={() => handleCourseClick(course.id, course.name)}
              />
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default HomePage;