import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSentences } from '../redux/textsSlice';
import { useParams, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Button, Form, Alert } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { transformLang } from './Utils';
import EditTranslationModal from './EditTranslationModal';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Импортируем стили Quill
import { convert } from 'html-to-text';
import QuillImageResize from 'quill-image-resize';

// Регистрируем плагин
ReactQuill.Quill.register('modules/imageResize', QuillImageResize);

const SentenceList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sentences, loading, error, user } = useSelector(state => ({
    sentences: state.texts.sentences,
    loading: state.texts.loading,
    error: state.texts.error,
    user: state.texts.user, 
  }));
  const [showTranslations, setShowTranslations] = useState({});
  const { articleId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [selectedSentence, setSelectedSentence] = useState(null);

  const location = useLocation();
  const articleData = location.state;

  const articleText = articleData.text;
  const articleTitle = articleData.title;
  const articleTextLang = articleData.text_language;
  const articleTransLang = articleData.translation_language;

  const [isPlaying, setIsPlaying] = useState(false);
  const [utterance, setUtterance] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    dispatch(fetchSentences(articleId));
  }, [dispatch, articleId]);

  const stripHtmlTags = (html) => {
    return convert(html, {
      formatters: {
        // Create a formatter.
        'fooBlockFormatter': function (elem, walk, builder, formatOptions) {
          builder.openBlock({ leadingLineBreaks: formatOptions.leadingLineBreaks || 1 });
          walk(elem.children, builder);
          builder.addInline('!');
          builder.closeBlock({ trailingLineBreaks: formatOptions.trailingLineBreaks || 1 });
        }
      },
      selectors: [
        // Assign it to `foo` tags.
        {
          selector: 'foo',
          format: 'fooBlockFormatter',
          options: { leadingLineBreaks: 1, trailingLineBreaks: 1 }
        }
      ]
    });
  };

  const handlePlay = () => {
    if (!user || !user.text_language) {
      console.error("User data is not available or text_language is missing.");
      return;
    }

    const cleanText = stripHtmlTags(articleText);
    console.log(cleanText); // Проверка очищенного текста

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'speak', lang: transformLang(user.text_language), text: cleanText, comp: 'SentenceList', voice: user.text_voice }));
    } else {
      const newUtterance = new SpeechSynthesisUtterance(cleanText);
      newUtterance.lang = transformLang(user.text_language);
      setUtterance(newUtterance);
      speechSynthesis.speak(newUtterance);
    }
    setIsPlaying(true);
  };

  const handleStop = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'stop', comp: 'SentenceList', voice: user.text_voice }));
    } else {
      speechSynthesis.cancel();
    }
    setIsPlaying(false);
  };

  const handlePlayAudio = (text, lang) => {
    if (!user || !user.text_language) {
      console.error("User data is not available or text_language is missing.");
      return;
    }

    const lang_tr = transformLang(user.text_language);
    
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'speak', text, lang_tr, comp: 'SentenceList', voice: user.text_voice }));
    } else {
      const msg = new SpeechSynthesisUtterance(text);
      msg.lang = lang_tr;
      window.speechSynthesis.speak(msg);
    }
  };

  const handlePlayAll = () => {
    if (!user || !user.text_language) {
      console.error("User data is not available or text_language is missing.");
      return;
    }

    setCurrentIndex(0);
    setIsPlaying(true);
  };

  useEffect(() => {
    if (isPlaying && currentIndex < sentences.length) {
      const sentence = sentences[currentIndex];
      handlePlayAudio(sentence.text, articleTextLang);
      const timeoutId = setTimeout(() => {
        setCurrentIndex(currentIndex + 1);
      }, 1000); // Задержка в 1 секунду между предложениями
      return () => clearTimeout(timeoutId); // Очистка таймаута при размонтировании
    } else {
      setIsPlaying(false);
    }
  }, [isPlaying, currentIndex, sentences, articleTextLang, user]);

  const toggleTranslation = (sentenceId) => {
    setShowTranslations({
      ...showTranslations,
      [sentenceId]: !showTranslations[sentenceId],
    });
  };

  const handleShowDictionary = (sentId, sentText) => {
    const sentenceData = {
      id: sentId,
      text: sentText,
    };

    navigate(`/dictionary/${sentId}`, { state: sentenceData });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const filteredSentences = sentences.filter(sentence => sentence.article.id === parseInt(articleId));

  const sentencesByTitle = filteredSentences.reduce((acc, sentence) => {
    if (!acc[sentence.article.name]) {
      acc[sentence.article.name] = [];
    }
    acc[sentence.article.name].push(sentence);
    return acc;
  }, {});

  return (
    <div className="container mt-1">
      <button onClick={() => navigate(-1)} className="btn btn-outline-secondary me-2">
        <i className="bi bi-arrow-left"></i>
      </button>

      <h5 className="mt-3 .font-weight-bold">{articleTitle}</h5>
      <div className="mb-1 .font-weight-bold">
        <Form.Group controlId="articleText">
          <Form.Label></Form.Label>
          <ReactQuill
            value={articleText}
            readOnly={true}
            theme="snow"
            placeholder="Article text..."
            style={{ height: 'auto', marginBottom: '10px' }}
            modules={{
              imageResize: {
                modules: ['Resize', 'DisplaySize', 'Toolbar'],
              },
              toolbar: false,
            }}
          />
        </Form.Group>
        <button className="mt-3 btn btn-outline-secondary me-2" onClick={handlePlay} disabled={isPlaying}>
          <i className="bi bi-play-fill"></i>Play Text
        </button>
        <button className="mt-3 btn btn-outline-secondary me-2" onClick={handleStop} disabled={!isPlaying}>
          <i className="bi bi-stop-fill"></i>
        </button>
      </div>
      {Object.keys(sentencesByTitle).map(title => (
        <div key={title}>
          <h6 className="text-divider"><span>Sentence List</span></h6>
          <button className="mt-3 btn btn-outline-secondary m-2" onClick={handlePlayAll} disabled={isPlaying}>
          <i className="bi bi-play-fill"></i> Play All
        </button>
        <button className="mt-3 btn btn-outline-secondary m-2" onClick={handleStop} disabled={!isPlaying}>
          <i className="bi bi-stop-fill"></i>
        </button>

          <ul className="list-group">
            {sentencesByTitle[title].map(sentence => (
              <li key={sentence.id} className="list-group-item ">
                <p className="p-2"><strong>{sentence.text}</strong></p>
                {showTranslations[sentence.id] && (
                  <p className="mt-2"><small>{sentence.translation}</small></p>
                )}
                <div className="mt-2 d-flex justify-content-between">
                  <button onClick={() => handlePlayAudio(sentence.text, articleTextLang)} className="btn btn-outline-secondary btn-sm me-2">
                    <i className="bi bi-play-fill"></i>
                  </button>
                  <button onClick={() => toggleTranslation(sentence.id)} className="btn btn-outline-secondary btn-sm me-2">
                    <i className="bi bi-eye"></i>
                  </button>
                  <button onClick={() => handleShowDictionary(sentence.id, sentence.text)} className="btn btn-outline-secondary btn-sm me-2">
                    <i className="bi bi-book"></i>
                  </button>
                  <button onClick={() => { setSelectedSentence(sentence); setShowModal(true); }} className="btn btn-outline-secondary btn-sm me-2">
                    <FaEdit />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
      {selectedSentence && (
        <EditTranslationModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          sentence={selectedSentence}
        />
      )}
    </div>
  );
};

export default SentenceList;


