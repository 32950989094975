import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCourse, deleteCourse } from '../redux/textsSlice';
import { Button, Form, ListGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';

const ManageCoursesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { courses, user } = useSelector(state => ({
    courses: state.texts.courses,
    user: state.texts.user,
  }));

  const [courseName, setCourseName] = useState('');
  const [courseDescription, setCourseDescription] = useState('');
  const [isPublic, setIsPublic] = useState(false);

  const handleAddCourse = () => {
    dispatch(addCourse({ name: courseName, description: courseDescription, created_by: user.id, is_public: isPublic }));
    setCourseName('');
    setCourseDescription('');
    setIsPublic(false);
  };

  const handleDeleteCourse = (courseId) => {
    dispatch(deleteCourse(courseId));
  };

  return (
    <div className="container mt-1">
      <button onClick={() => navigate(-1)} className="btn btn-outline-secondary me-2">
        <i className="bi bi-arrow-left"></i>
      </button>
      <h1>Manage courses</h1>
      <Form>
        <Form.Group controlId="formCourseName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter the course name"
            value={courseName}
            onChange={(e) => setCourseName(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formCourseDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter a course description"
            value={courseDescription}
            onChange={(e) => setCourseDescription(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formIsPublic">
          <Form.Check
            type="checkbox"
            label="Public course"
            checked={isPublic}
            onChange={(e) => setIsPublic(e.target.checked)}
          />
        </Form.Group>
        <Button className="mt-4" variant="outline-success" onClick={handleAddCourse}>Add course</Button>
      </Form>

      <h2 className="mt-4">Courses</h2>
      <ListGroup>
        {courses.filter(course => course.created_by === user.id).map(course => (
          <ListGroup.Item key={course.id} className="d-flex justify-content-between align-items-center">
            <div>
              <h5>{course.name}</h5>
              <p>{course.description}</p>
            </div>
            <Button variant="outline-danger" onClick={() => handleDeleteCourse(course.id)}>
              <FaTrash />
            </Button>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default ManageCoursesPage;