import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { updateSentenceTranslation } from '../redux/textsSlice';

const EditTranslationModal = ({ show, handleClose, sentence }) => {
  const [translation, setTranslation] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (sentence) {
      setTranslation(sentence.translation);
    }
  }, [sentence]);

  const handleSave = () => {
    dispatch(updateSentenceTranslation({ id: sentence.id, translation }));
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Translation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="translation">
          <Form.Label>Translation</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={translation}
            onChange={(e) => setTranslation(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditTranslationModal;