// import React, { useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { useNavigate, Link } from 'react-router-dom';
// import { loginUser, loginWithGoogle } from '../redux/textsSlice';
// import { GoogleLogin } from '@react-oauth/google';
// import { Form, Button, Alert, Row, Col, InputGroup } from 'react-bootstrap';
// import './TextDivider.css';
// import Logo from './Logo'; // Импортируем компонент Logo

// const Login = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [error, setError] = useState(null);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       // await dispatch(loginUser({ email, password })).unwrap();
//       dispatch(loginUser({ email, password }));

//       navigate('/'); // Перенаправляем на главную страницу после успешного логина
//     } catch (err) {
//       setError('Login failed. Please try again.');
//     }
//   };

//   const handleGoogleLoginSuccess = (response) => {
//     const token = response.credential;
//     // Отправка токена на бекенд для проверки и авторизации
//     dispatch(loginWithGoogle(token))
//       .then(() => {
//         navigate('/');
//       })
//       .catch(error => {
//         setError(error.message);
//       });
//   };

//   const handleGoogleLoginFailure = (error) => {
//     setError('Google login failed');
//   };

//   return (
//     <div className="container mt-1">
//       <div className="d-flex justify-content-center text-center mb-1">
//         <Logo />
//       </div>
//       {error && <Alert variant="danger">{error}</Alert>}
//       <Form onSubmit={handleSubmit}>
//         <InputGroup className="mb-3">
//           <InputGroup.Text id="basic-addon1"><i className="bi bi-envelope-at"></i></InputGroup.Text>
//           <Form.Control
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             placeholder="Enter e-mail"
//             aria-label="E-mail"
//             aria-describedby="basic-addon1"
//           />
//         </InputGroup>
//         <InputGroup className="mb-3">
//           <InputGroup.Text id="basic-addon2"><i className="bi bi-key"></i></InputGroup.Text>
//           <Form.Control
//             type="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             placeholder="Enter password"
//             aria-label="Password"
//             aria-describedby="basic-addon2"
//           />
//         </InputGroup>
//         <Button variant="outline-primary" type="submit" className="btn-outline-primary w-100 mt-3">
//           Login
//         </Button>
//       </Form>

//       <h6 className="text-divider">
//         <span>or</span>
//       </h6>

//       <div className="mt-1 d-flex justify-content-center">
//         <GoogleLogin
//           onSuccess={handleGoogleLoginSuccess}
//           onError={handleGoogleLoginFailure}
//           render={(renderProps) => (
//             <Button
//               onClick={renderProps.onClick}
//               disabled={renderProps.disabled}
//               className="w-100"
//             >
//               Login with Google
//             </Button>
//           )}
//         />
//       </div>

//       <div className="mt-1">
//         <Row className="justify-content-center w-100">
//           <Col xs="auto">
//             <h6 className="text-divider">
//               <span>I'm new here.</span>
//             </h6>
//             <Link className="btn btn-outline-secondary me-2 w-100" to="/register">
//               Register
//             </Link>
//           </Col>
//           <Col xs="auto">
//             <h6 className="text-divider">
//               <span>Forgot password?</span>
//             </h6>
//             <Link
//               className="btn btn-outline-secondary me-2 w-100"
//               to="/password-reset-request"
//             >
//               Reset password
//             </Link>
//           </Col>
//         </Row>
//       </div>
//     </div>
//   );
// };

// export default Login;


import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { loginUser, loginWithGoogle, fetchUserProfile } from '../redux/textsSlice';
import { GoogleLogin } from '@react-oauth/google';
import { Form, Button, Alert, Row, Col, InputGroup } from 'react-bootstrap';
import './TextDivider.css';
import Logo from './Logo'; // Импортируем компонент Logo

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(loginUser({ email, password })).unwrap();
      await dispatch(fetchUserProfile()).unwrap(); // Загружаем профиль пользователя после успешного логина
      navigate('/'); // Перенаправляем на главную страницу после успешного логина
    } catch (err) {
      setError('Login failed. Please try again.');
    }
  };

  const handleGoogleLoginSuccess = async (response) => {
    const token = response.credential;
    try {
      await dispatch(loginWithGoogle(token)).unwrap();
      await dispatch(fetchUserProfile()).unwrap(); // Загружаем профиль пользователя после успешного логина
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleLoginFailure = (error) => {
    setError('Google login failed');
  };

  return (
    <div className="container mt-1">
      <div className="d-flex justify-content-center text-center mb-1">
        <Logo />
      </div>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit}>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1"><i className="bi bi-envelope-at"></i></InputGroup.Text>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter e-mail"
            aria-label="E-mail"
            aria-describedby="basic-addon1"
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon2"><i className="bi bi-key"></i></InputGroup.Text>
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            aria-label="Password"
            aria-describedby="basic-addon2"
          />
        </InputGroup>
        <Button variant="outline-primary" type="submit" className="btn-outline-primary w-100 mt-3">
          Login
        </Button>
      </Form>

      <h6 className="text-divider">
        <span>or</span>
      </h6>

      <div className="mt-1 d-flex justify-content-center">
        <GoogleLogin
          onSuccess={handleGoogleLoginSuccess}
          onError={handleGoogleLoginFailure}
          render={(renderProps) => (
            <Button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              className="w-100"
            >
              Login with Google
            </Button>
          )}
        />
      </div>

      <div className="mt-1">
        <Row className="justify-content-center w-100">
          <Col xs="auto">
            <h6 className="text-divider">
              <span>I'm new here.</span>
            </h6>
            <Link className="btn btn-outline-secondary me-2 w-100" to="/register">
              Register
            </Link>
          </Col>
          <Col xs="auto">
            <h6 className="text-divider">
              <span>Forgot password?</span>
            </h6>
            <Link
              className="btn btn-outline-secondary me-2 w-100"
              to="/password-reset-request"
            >
              Reset password
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Login;